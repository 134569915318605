import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetPiano_EstrazioniToInit, setPiano_EstrazioniMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deletePiano_Estrazioni } from "services/piano_estrazioniService";
import DetailRicercaEstesaModal from "template/DetailRicercaEstesaModal";
import { getCodici_Ateco } from "services/codici_atecoService";
import LoadingSpinner from "components/LoadingSpinner";
import Layout from "template";
import { getComuni_Catastali } from "services/comuni_catastaliService";
//import { useNavigate } from "react-router";
import { useNavigate } from 'react-router-dom';
import { getRicercaElencoImpresaPreventivoDiretto, getRichiestaElencoImpresa } from "services/richiesta_elenco_impresa_esteso";
import ConfirmationPreventivoElenco from "template/ConfirmationPreventivoElenco";
import ConfirmationDifferita from "template/ConfirmationDifferita";


type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey) => void;
};
export const Piano_EstrazioniTable: React.FC<Props> = ({ hideShowForm, handleRowEdit, getData }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [showDatiRicerca, setDatishowRicerca] = useState(false);
    const [rowData, setRowData] = useState(undefined);
    const [shoWRicerca, setShoWRicerca] = useState(undefined);
    const rData = useSelector((state: RootState) => state.piano_estrazioni);
    const [isLoading, setIsLoading] = useState(false);

    const [showDatiRichiestaPreventivo, setshowDatiRichiestaPreventivo] = useState(false);
    const [rowDataPreventivo, setRowDataPreventivo] = useState(undefined);
    const [rowDataResponsePreventivo, setrowDataResponsePreventivo] = useState(undefined)

    const [showConfermaRichiestaElenco, setshowConfermaRichiestaElenco] = useState(false);
    const [rowDataRispostaElenco, setrowDataRispostaElenco] = useState(undefined);
    const [rowResponseDataRispostaElenco, setrowResponseDataRispostaElenco] = useState(undefined)

    type OptionTypeRowDataPreventivo = {
        esito: string;
        numero_posizioni_preventivo: string;
        numero_posizioni_disponibili: string;
        numero_posizioni_residue: string;
        messaggio: string;


    };

    const handleSearch = () => {
        if (search.length > 0) {
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
        }
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        await getData(page, newPerPage, '');
    }
    const handlePageChange = (page) => {
        getData(page, rData.pageSize, '');
    };
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    }
    useEffect(() => {
        
        if (rData && rData.list && rData.list.length === 0) {
            dispatch(resetPiano_EstrazioniToInit());
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
            selectCodiciAteco()
            selectComuni()
        }
    })
    const handleReset = () => {
        setSearch('');
        dispatch(resetPiano_EstrazioniToInit());
        getData(Constant.defaultPageNumber, rData.pageSize, '');
    }
    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deletePiano_Estrazioni(rowData.id);
            if (response) {
                dispatch(resetPiano_EstrazioniToInit());
                dispatch(setPiano_EstrazioniMessage("Deleted Successfully"));
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                setShowDelete(false);
            } else {
                dispatch(setPiano_EstrazioniMessage("Some error occured!"));
            }
        }
    }

    const handleRichiestaElenco = (rowData) =>{
        //console.log(rowData.dati)
        var split = rowData.dati.dati_ricerca.split("_dati_")
        //console.log(split[0])
        navigate("/ricerca_unica/"+rowData.dati.id+"PE"+rowData.dati.nome_file, {replace: true})


    }

    const handleServerPreventivoRichiesta = async (row) => {
        const rowDataPreventivo: OptionTypeRowDataPreventivo = {
            esito: "",
            numero_posizioni_preventivo: "",
            numero_posizioni_disponibili: "",
            numero_posizioni_residue: "",
            messaggio: ""
        }
        setIsLoading(true);
        getRicercaElencoImpresaPreventivoDiretto(row.dati.dati_ricerca).then((response) => {
          if (response != null && response.data.message === "OK") {
            //dispatch(setElenco_Imprese_Esteso_EstrazioneList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
            //dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Visura Camerale Richiesta"));
           

            if(response.data.status === 300){
                rowDataPreventivo.esito = "300";
                rowDataPreventivo.messaggio = response.data.response;
            }else if(response.data.status === 200){

                const posizioni_disponibili = response.data.anagrafiche;//da prendere da abbonamento
                const posizioni_preventivo = response.data.response.numeroposizioni[0];
                const posizioni_preventivo_2 = response.data.response.posizionepreventivo;
                const posizioni_residue = Number(posizioni_disponibili) - Number(posizioni_preventivo);
                let nessuna_posizione = false;
                if(Number(posizioni_preventivo) === 0) nessuna_posizione = true;

                if(Number(posizioni_preventivo) === 0 && nessuna_posizione){
                    rowDataPreventivo.esito = "201";
                    rowDataPreventivo.messaggio = "Non hai sufficienti crediti per poter visualizzare questo elenco";
                    rowDataPreventivo.numero_posizioni_preventivo = posizioni_preventivo.toString();
                    rowDataPreventivo.numero_posizioni_disponibili = posizioni_disponibili.toString();

                }else if(posizioni_residue < 0 && !nessuna_posizione){
                    rowDataPreventivo.esito = "301";
                    rowDataPreventivo.messaggio = "Non hai sufficienti crediti per poter visualizzare questo elenco";
                    rowDataPreventivo.numero_posizioni_preventivo = posizioni_preventivo.toString();
                    rowDataPreventivo.numero_posizioni_disponibili = posizioni_disponibili.toString();

                }else{
                    rowDataPreventivo.esito = "200";
                    rowDataPreventivo.messaggio = "La ricerca ha prodotto n: "+ posizioni_preventivo.toString();
                    rowDataPreventivo.numero_posizioni_preventivo = posizioni_preventivo.toString();
                    rowDataPreventivo.numero_posizioni_disponibili = posizioni_disponibili.toString();
                    rowDataPreventivo.numero_posizioni_residue = posizioni_residue.toString();
                    setDatishowRicerca(false);
                    setrowDataResponsePreventivo(response.data.response)

                }
            }

            setIsLoading(false);
            setRowDataPreventivo(rowDataPreventivo);
            setshowDatiRichiestaPreventivo(true)
            
          } else {
            //dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Visura non richiedibile"));
            if(response.data.status === 500){
                rowDataPreventivo.esito = "500";
                rowDataPreventivo.messaggio = "Abbiamo rilevato un errore generico! Riprovare!";
            }
            setDatishowRicerca(false);
            setIsLoading(false);
            setRowDataPreventivo(rowDataPreventivo);
            setshowDatiRichiestaPreventivo(true)
          }
        })
    }

    const handleRowSelection = (row) => {
        //console.log(row); // Row Selection Functionality can be written here
    }
    const handleAddButtonClick = () => {
        dispatch(setPiano_EstrazioniMessage(''));
        hideShowForm('add');
    }

    type datiRicerca = {
        dati: any;
        ateco: any;
        comuni: any;
    };

    const handleRowDetail = (rowData) => {
        
        selectCodiciAteco()
        selectComuni()
        
        setIsLoading(true)
        const timer = setTimeout(() => {
            const datiRicercaObj : datiRicerca = {
                dati: null,
                ateco: null,
                comuni: null,
            }
    
            datiRicercaObj.dati = rowData
            datiRicercaObj.ateco = selectedCodiciAteco
            datiRicercaObj.comuni = selectedComuni
            setRowData(datiRicercaObj);
            setDatishowRicerca(true);
            setIsLoading(false)
        }, 3000);
        
      }

      type OptionTypeDropDown = {
        value: string;
        label: string;
        };

    
      const multiValueCodiceAteco =  null
      const codiciAtacoLista: OptionTypeDropDown [] = []
      const [selectedCodiceAteco, setSelectedCodiceAteco] = useState(multiValueCodiceAteco);
      const [selectedCodiciAteco, setSelectedCodiciAteco] = useState(codiciAtacoLista);
  
      const selectCodiciAteco = () => {
          getCodici_Ateco(1, 1000, '').then((response)=> {
              if (response && response.records) {
                const codiciAteco : OptionTypeDropDown[] = []
                response.records?.map((item, i) => {
                    const ateco: OptionTypeDropDown = {
                        value: "",
                        label: ""
                    }
                    ateco.label = item.descrizione
                    ateco.value = item.codice
                    codiciAteco.push(ateco)
                })
                setSelectedCodiciAteco(codiciAteco)
              }
          })
      }



        const multiValueComuni =  null
        const comuniLista: OptionTypeDropDown [] = []
        const [selectedComune, setSelectedComune] = useState(multiValueComuni);
        const [selectedComuni, setSelectedComuni] = useState(comuniLista);

        const selectComuni = () => {
            getComuni_Catastali(1, 1000, '').then((response)=> {
                if (response && response.records) {
                    const comuni : OptionTypeDropDown[] = []
                    response.records?.map((item, i) => {
                        const comune: OptionTypeDropDown = {
                            value: "",
                            label: ""
                        }
                        comune.label = item.nomecomune
                        comune.value = item.codicecomune
                        comuni.push(comune)
                    })
            
                    setSelectedComuni(comuni)
                }
            })
        }


    const handleServerRichestaEleneco = () => {
        if(rowDataResponsePreventivo != null){
            getRichiestaElencoImpresa(rowDataResponsePreventivo).then((response) => {
                if (response != null && response.data.message === "OK") {
                    setshowDatiRichiestaPreventivo(false)
                    setrowResponseDataRispostaElenco(response.data)
                    setshowConfermaRichiestaElenco(true)
                    
                    
                }else{
                    setshowDatiRichiestaPreventivo(false)
                    setrowResponseDataRispostaElenco(response.data)
                    setshowConfermaRichiestaElenco(true)
                    
                }
            });
        }
    }

    const handleServerRedirectDifferita = () => {

        navigate('/ws_ticket', { replace: true });
    }

    const handleRowSendRicerca = (row) => {

        navigate('/ricerca_imprese', { state: { row, tipo:"estesa" }, replace: true });
    }

    const handleRowDettaglio = (row) => {
            
        return(<Button variant="link" className="btn-sm" onClick={() => handleRowDetail(row)}><i className="fa fa-search-plus icon-size-normal"></i></Button>);
      }
    
      const handleRowEditAction = (row) => {
            
        return(<Button variant="link" className="btn-sm" onClick={() => handleRowEdit(row)}><i className="fa fa-pencil icon-size-normal"></i></Button>);
      }

      const handleRowSendAction = (row) => {
            
        return(<Button variant="link" className="btn-sm" onClick={() => handleRowSendRicerca(row)}><i className="fa fa-pencil icon-size-normal"></i></Button>);
      }

    

    const columns = [
        {
            name: 'Dettaglio',
            button: true,
            cell: (row) => handleRowDettaglio(row),
        },{
            name: 'Modifica',
            button: true,
            cell: (row) => handleRowEditAction(row),
        },
        // {
        //     name: 'Richiedi',
        //     button: true,
        //     cell: (row) => handleRowSendAction(row),
        // },
       //{name: 'id', selector: row => row.id, sortable: true},
        // {name: 'id_cliente', selector: row => row.id_cliente, sortable: true},
        // {name: 'id_abbonamento', selector: row => row.id_abbonamento, sortable: true},
        {name: 'data_estrazione', selector: row => row.data_estrazione, sortable: true},
        {name: 'numero_anagrafiche', selector: row => row.numero_anagrafiche, sortable: true},
        // {name: 'nome_file', selector: row => row.nome_file, sortable: true},
        {name: 'nome ricerca', selector: row => row.nome_ricerca, sortable: true},
        // {name: 'dati ricerca', selector: row => row.dati_ricerca, sortable: true},
       
        // {
        //     name: '',
        //     button: true,
        //     cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowDeleteClick(row)}>Delete</Button>,
        // },
    ];
    return (
        <div>
            {isLoading ? <LoadingSpinner /> : isLoading}
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary">Aggiorna Lista ({rData.totalCount})
                    <Button variant="light" className="btn-circle btn-sm ml-2" onClick={handleReset}><i className="fa fa-refresh"></i></Button>
                    {/* <Button className="btn-icon-split float-right" onClick={handleAddButtonClick}>
                        <span className="icon text-white-50">
                            <i className="fas fa-add"></i>
                        </span>
                        <span className="text">Add New</span>
                    </Button> */}
                </h6>

            </Card.Header>
            <Card.Body>
                <Col className="ml-auto" md={3} xs={12} xl={3}>
                    <InputGroup>
                        <Form.Control
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-search"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <Button disabled={search.length <= 2} variant="dark" className="rounded-0 rounded-right" id="button-search" onClick={handleSearch}>
                            Search
                        </Button>
                    </InputGroup>
                </Col>
                <div className="table-responsive">
                    <DataTable
                        selectableRows={false}
                        onSelectedRowsChange={handleRowSelection}
                        paginationPerPage={Constant.defaultPageNumber}
                        paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
                        columns={columns} data={rData.list}
                        onChangeRowsPerPage={handlePerRowsChange}
                        paginationTotalRows={rData.totalCount}
                        className="table table-bordered"
                        pagination
                        paginationServer
                        onChangePage={handlePageChange}></DataTable>
                </div>
            </Card.Body>
            <ConfirmationModal buttonNegative="Cancel" buttonPositive="Delete" title="Delete Confirmation" show={showDelete} body={"Are you sure?"} onNegative={() => setShowDelete(false)} onPositive={handleServerDelete} />
            <DetailRicercaEstesaModal buttonNegative="Chiudi" buttonPositive="Richiedi Elenco" title="Dati Ricerca" show={showDatiRicerca} body={rowData} onNegative={() => setDatishowRicerca(false)} onPositive={() => handleRichiestaElenco(rowData)} />
            <ConfirmationPreventivoElenco
                            buttonNegative="Annulla" 
                            buttonPositive="Richiedi" 
                            title="Ricerca Imprese Estesa" 
                            show={showDatiRichiestaPreventivo} 
                            body={rowDataPreventivo} 
                            onNegative={() => setshowDatiRichiestaPreventivo(false)}
                            onPositive={handleServerRichestaEleneco}  
                        
                        />
            <ConfirmationDifferita
                            buttonNegative="Chiudi" 
                            buttonPositive="Chiudi" 
                            title="Richistea Elenco Imprese" 
                            show={showConfermaRichiestaElenco} 
                            body={rowResponseDataRispostaElenco} 
                            onNegative={() => setshowConfermaRichiestaElenco(false)}
                            onPositive={handleServerRedirectDifferita}  
                        />
        </Card>
        </div>
    );
}

