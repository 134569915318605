import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetArchivioToInit, setArchivioMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteArchivio, getAllDocumenti, updateLettura } from "services/archivioService";
import LoadingSpinner from "../LoadingSpinner";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router";
import ReactPaginate from "react-paginate";

type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey) => void;
};
export const ArchivioTable: React.FC<Props> = ({ hideShowForm, handleRowEdit, getData }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);
    const rData = useSelector((state: RootState) => state.archivio);
    const [isLoading, setIsLoading] = useState(false);

    const elencoImprese: any [] = []
    const [lstElencoImprese, setListElencoImprese] = useState(elencoImprese);
    const [postsPerPage] = useState(10);
    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [countImprese, setcountImprese] = useState(elencoImprese.length);

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };


    const handleSearchButton = () => {

        filterBySearch(search)
    
    }

    const getAllPosts = async (elenco_input) => {
        let res = rData.list//rDataElenco
        if(elenco_input != null && elenco_input.length > 0){
            res = elenco_input
        }
        const data = res
        let slice = null;
        if(res.length < 11){
             //res.list;
            slice = data
        }else{

            if(offset > 1){
                slice = data.slice((offset - 1) * 10 , (((offset - 1) * 10) + postsPerPage) -1)
            }else{
                slice = data.slice(offset - 1 , offset - 1 + postsPerPage)
            }
            
        }
        // For displaying Data
        const postData = getPostData(slice)
      
        // Using Hooks to set value
        setAllPosts(postData)
        setPageCount(Math.ceil(data.length / postsPerPage))

        setcountImprese(res.length)
    }

    const [linkScaduto, setLinkScaduto] = useState(false)
    const scandezaFileReport = (data_archivio) => {

        if(data_archivio != null){
            let data_scadenza = moment(data_archivio).add(3, 'months')
            let data_oggi = moment()
    
            //console.log(data_oggi.format('DD/MM/YYYY HH:mm:ss'))
            if(data_oggi.isAfter(data_scadenza)) setLinkScaduto(true);
            
            return moment(data_scadenza).format('DD/MM/YYYY HH:mm:ss')
        }else{
            return "-"
        }
        


    }

    const getPostData = (data) => {
        return (
          data.map(row => 
            <Row className="shadow box-ricerca-elenco row  margin-row">
            <Col md={2} xs={12} xl={2} className="center-block-custom align-center cursor-pointer icon-archivio-displays" aria-disabled={ isLinkScaduto (row)} onClick={() => handleViewFile(row)}>
            {handleIcon(row)}
            {handleLinkDisponibile(row)}   
            </Col>
            
            <Col md={10} xs={12} xl={10}>
                <Row>
                    <div className="col-sm-6 color-gray-pg">Servzio: <p><strong className="color-blue">{valoreDato(row.descrizione_prodotto)}</strong></p></div>
                    <div className="col-sm-6 color-gray-pg">Data: <p><strong className="color-blue">{moment(row.data_archivio).format('DD/MM/YYYY HH:mm:ss')}</strong></p></div>
                    <div className="col-sm-6 color-gray-pg">Anagrafica: <p><strong className="color-blue">{valoreDato(row.anagrafica)}</strong></p></div>
                    <div className="col-sm-6 color-gray-pg">Data Scadenza: <p><strong className="color-blue">{scandezaFileReport(row.data_archivio)}</strong></p></div>
                </Row>
            </Col>
            <Col md={2} xs={12} xl={2} className="center-block-custom align-center cursor-pointer icon-archivio-display" aria-disabled={ isLinkScaduto (row)} onClick={() => handleViewFile(row)}>
            {handleIcon(row)}
            {handleLinkDisponibile(row)}   
            </Col>
            
            </Row>
            )
        )
      
      }

      const filterBySearch = (event) => {
        const query = event
        let query_stato = query
        var updatedList = lstElencoImprese
        updatedList = updatedList.filter((item) => {
            if(
            (item.descrizione_prodotto != null && item.descrizione_prodotto.toLowerCase().indexOf(query.toLowerCase()) !== -1) || 
            (item.data_archivio != null && moment(item.data_archivio).format('DD/MM/YYYY HH:mm:ss').toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
            (item.data_archivio != null && scandezaFileReport(item.data_archivio).toLowerCase().indexOf(query.toLowerCase()) !== -1) || 
            (item.anagrafica != null && item.anagrafica.toLowerCase().indexOf(query.toLowerCase()) !== -1)
            ){
                return item
            }
        });
        // Trigger render with updated values
        setListElencoImprese(updatedList)
        getAllPosts(updatedList)
    };


    const handleSearch = () => {
        if (search.length > 0) {
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
        }
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        await getData(page, newPerPage, '');
    }
    const handlePageChange = (page) => {
        getData(page, rData.pageSize, '');
    };
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    }
    // useEffect(() => {
    //     if (rData && rData.list && rData.list.length === 0) {
    //         dispatch(resetArchivioToInit());
    //         getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
    //     }
    // })

    useEffect(() => {
        if (rData && rData.list && rData.list.length === 0) {
             dispatch(resetArchivioToInit());
             getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
             getAllPosts(rData.list);
        }else{
            getAllPosts(rData.list);
            setListElencoImprese(rData.list)
        }
    },[rData, rData.list.length, offset])

    const handleReset = () => {
        setSearch('');
        dispatch(resetArchivioToInit());
        getData(Constant.defaultPageNumber, rData.pageSize, '');
    }
    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deleteArchivio(rowData.id_archivio);
            if (response) {
                dispatch(resetArchivioToInit());
                dispatch(setArchivioMessage("Deleted Successfully"));
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                setShowDelete(false);
            } else {
                dispatch(setArchivioMessage("Some error occured!"));
            }
        }
    }

    const handleRowSelection = (row) => {
        //console.log(row); // Row Selection Functionality can be written here
    }
    const handleAddButtonClick = () => {
        dispatch(setArchivioMessage(''));
        hideShowForm('add');
    }

    const base64toBlobPDF = (data: string) => {
        const base64WithoutPrefix = data;

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: 'application/pdf' });
    };

    const base64toBlobZIP = (data: string) => {
            const base64WithoutPrefix = data;
            const bytes = atob(base64WithoutPrefix);
            let length = bytes.length;
            let out = new Uint8Array(length);
    
            while (length--) {
                out[length] = bytes.charCodeAt(length);
            }
    
            return new Blob([out], { type: 'application/zip' });
        };

    const isLinkScaduto = (data_archivio) =>{
        
        let scaduto = false
        let data_scadenza = moment(data_archivio).add(3, 'months')
        let data_oggi = moment()

        if(data_oggi.isAfter(data_scadenza)) scaduto = true;

        return scaduto

    }

    
    const handleViewFile = async (row) => {
        

        let scaduto = isLinkScaduto (row.data_archivio)

        if(!scaduto){
            setIsLoading(true);
            let action = 1; 
            if(row.file.includes(".zip")){
                action = 1;
                getAllDocumenti(row.id_archivio, action).then(response => {
                    const blob = base64toBlobZIP(response.data.response);
                    const fileURL = URL.createObjectURL(blob);
                    window.open(fileURL, "_blank");
                    setIsLoading(false);
                })
                    .catch(error => {
                        ////console.log(error);
                        setIsLoading(false);
                });
            }else if(row.file.includes(".pdf")){
                // getAllDocumenti(row.id_archivio, action).then(response => {
                //     const base64Pdf = response.data.response;
                //     const binary = atob(base64Pdf);
                //     const array = Uint8Array.from(binary, char => char.charCodeAt(0));
                //     const blob = new Blob([array], { type: 'application/pdf' });
                //     const url = URL.createObjectURL(blob);
                //     window.open(url); // Apre il PDF in una nuova finestra
                //     // const blob = base64toBlobPDF(response.data.response);
                //     // const fileURL = URL.createObjectURL(blob);
                //     // window.open(fileURL, "_blank");
                //     setIsLoading(false);
                // })
                //     .catch(error => {
                //         ////console.log(error);
                //         setIsLoading(false);
                // });
                getAllDocumenti(row.id_archivio, action).then(async response => {
        let base64Pdf = response.data.response;

        let score = response.data.score
        let fileresponse = response.data.response
        // Calcolo della dimensione del file in byte
        const fileSizeInBytes = (base64Pdf.length * (3/4)) - (base64Pdf.endsWith('==') ? 2 : base64Pdf.endsWith('=') ? 1 : 0);

        // Definisci una soglia di dimensione, ad esempio 5 MB
        const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB

        if (response.data.type === 1) {
            // Se il file è troppo grande, esegui il download diretto
            // const binary = atob(base64Pdf);
            // const array = Uint8Array.from(binary, char => char.charCodeAt(0));
            // const blob = new Blob([array], { type: 'application/pdf' });
            // const url = URL.createObjectURL(blob);

            // // Esegui il download diretto
            // const a = document.createElement('a');
            // a.href = url;
            // a.download = 'documento.pdf'; // Nome del file
            // a.click();
            let file = "https://myimprese.it/areapersonale/archivioutente/"+score+"/"+fileresponse;
            // const a = document.createElement('a');
            // a.href = file;//response.data.response;
            // a.download = 'documento.pdf'; // Nome del file
            // a.click();

            // Forza il download del file
            const response = await fetch(file);

            if (!response.ok) {
                throw new Error(`Errore durante il fetch del file: ${response.status} ${response.statusText}`);
            }
    
            // Converte la risposta in un Blob
            const blob = await response.blob();
    
            // Crea un URL temporaneo per il Blob
            const url = window.URL.createObjectURL(blob);
    
            // Crea un elemento <a> per forzare il download
            const a = document.createElement('a');
            a.href = url;
            a.download = fileresponse;//"download.pdf"; // Nome del file scaricato
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
    
            // Rimuove l'URL temporaneo per liberare memoria
            window.URL.revokeObjectURL(url);

        } else {
            // Altrimenti apri il PDF in una nuova finestra
            base64Pdf = response.data.response;
            const binary = atob(base64Pdf);
            const array = Uint8Array.from(binary, char => char.charCodeAt(0));
            const blob = new Blob([array], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            window.open(url); // Apre il PDF in una nuova finestra
        }

        setIsLoading(false);
    })
    .catch(error => {
        //console.log(error);
        setIsLoading(false);
    });

            }else{
    
                if(row.id_prodotto == 12 || row.id_prodotto == 11 || row.id_prodotto == 13){
                    window.open(row.file, "_blank");
                }else{
                    
                    handleNavigateElenco(row)
                }    
                setIsLoading(false);
        }
        

        

        updateLettura(row.id_archivio).then(response => {
           //console.log("aggiornamento lettura avvenuto con successo") 
        })
        .catch(error => {
            //console.log("aggiornamento lettura NON avvenuto con successo") 
        });
        }
       
    }

    const handleNavigateElenco = (row) => {

        navigate('/elenco_imprese', {replace: true});

    }
    
    
    const handleIcon = (row) => {
        let coloricon = "color-blue"
        let scaduto = isLinkScaduto (row.data_archivio)
        if(scaduto) coloricon = "color-gray-pg"

        if(row.file.includes(".pdf")){
            return (<i className={"fa-regular fa-file-pdf icon-size-normal " + coloricon }></i>)
        }else if(row.file.includes(".zip")){
            return (<i className={"fa-regular fa-file-pdf icon-size-normal " + coloricon }></i>)
        }else{
            if(row.id_prodotto == 12 || row.id_prodotto == 11 || row.id_prodotto == 13){
                return (<i className={"fa-regular fa-file-pdf icon-size-normal " + coloricon}></i>)
            }else{
                return (<i className={"fa-solid fa-list icon-size-normal " + coloricon}></i>)
            }
        }

    }

    const handleLinkDisponibile = (row) =>{
        let scaduto = isLinkScaduto (row.data_archivio)

        if(scaduto){
            return <p className="font-size-12 color-gray-pg">scaduto</p>
        }else{
            return <p className="font-size-12 color-blue">disponibile</p>
        }

    }

    const columns = [
        {
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleViewFile(row)}>{handleIcon(row)}</Button>,
        },
        // {
        //     name: '',
        //     button: true,
        //     cell: (row) => <a href = {require('file:///C:/Users/salva/Documents/LAVORO/elencoimpresa/elencoimprese/areapersonale/archivioutente/1/2022121014254625425.zip')} target = "_blank" rel="noreferrer">Download Pdf</a>
        // },
    //    {name: 'id_archivio', selector: row => row.id_archivio, sortable: true},
    // {name: 'id_utente', selector: row => row.id_utente, sortable: true},
// {name: 'id_prodotto', selector: row => row.id_prodotto, sortable: true},
{name: 'servizio', selector: row => row.descrizione_prodotto, sortable: true},
{name: 'data_archivio', selector: row => moment(row.data_archivio).format('DD/MM/YYYY HH:mm:ss'), sortable: true},
{name: 'anagagrafica', selector: row => row.anagrafica, sortable: true},
// {name: 'costo', selector: row => row.costo, sortable: true},
{name: 'quantita_addebitata', selector: row => row.quantita_addebitata, sortable: true},
// {name: 'id_ticket', selector: row => row.id_ticket, sortable: true},
// {name: 'file', selector: row => row.file, sortable: true},
// {name: 'lettura', selector: row => row.lettura, sortable: true},
// {name: 'addebitare', selector: row => row.addebitare, sortable: true},

        // ,{
        //     name: '',
        //     button: true,
        //     cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowEdit(row)}>Edit</Button>,
        // },
        // {
        //     name: '',
        //     button: true,
        //     cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowDeleteClick(row)}>Delete</Button>,
        // },
    ];

    const valoreDato = (dato) => {
        if(dato == null || dato === ""){
            return "Dato non fornito"
        }else{
            return dato
        }
    }

    return (
        <>
        <div className="d-flex flex-column" >
            <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
                    <div className="col-sm-9">
                        <h4>Archivio Documenti</h4>
                    </div>
                    <div className="col-sm-3 text-align-right">
                        
                        <>
                        <Button variant="info" className="" onClick={() => handleReset()}><i className="fa fa-refresh"></i> Aggiorna ({countImprese})</Button>
                        
                        </>
                                        
                    </div>
            </Row>
        </div>
        <div>
            <Col className="ml-auto padding-bottom20" md={4} xs={12} xl={4}>
                    <InputGroup>
                        <Form.Control
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-search"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <Button disabled={search.length <= 2} variant="dark" className="rounded-0 rounded-right" id="button-search" onClick={handleSearchButton}>
                            Search
                        </Button>
                    </InputGroup>
                </Col>
        </div>
        <div className="d-flex flex-column" >
                
        {isLoading ? <LoadingSpinner /> : isLoading}

                
<div className="main-app">

<ReactPaginate
    previousLabel={"pre"}
    nextLabel={"succ"}
    breakLabel={"..."}
    breakClassName={"break-me"}
    pageCount={pageCount}
    onPageChange={handlePageClick}
    containerClassName={"pagination"}
    //subContainerClassName={"pages pagination"}
    activeClassName={"active"} />
    {/* Display all the posts */}
    {posts}
    {/* Using React Paginate */}
 </div>


<ConfirmationModal buttonNegative="Cancel" buttonPositive="Delete" title="Delete Confirmation" show={showDelete} body={"Are you sure?"} onNegative={() => setShowDelete(false)} onPositive={handleServerDelete} />
            </div>
 

                

        </>
        
    );
}

