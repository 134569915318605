import { APIService } from "services";
import { cercaOridineId, getValueMac } from "./pagamentoService";
import { createHash } from "crypto";
import { debug } from "console";


const hashMac_2 = async (stringaMac: string) => {
    try {
      //console.log(stringaMac)
      // Create a new SHA-1 digest instance using the crypto library
      const digest = await crypto.subtle.digest('SHA-1', new TextEncoder().encode(stringaMac));
  
      // Convert the digest to a hex string using Array.from and map
      const hex = Array.from(new Uint8Array(digest))
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('');
  
      return hex; ///hex.toUpperCase(); // Ensure uppercase output consistently
    } catch (error) {
      //console.log(error)
    }
  }

const checkValueMac = async (mac) => {

  getValueMac(mac).then((result) =>{
    //console.log(result)
  })
}

// const hashMac_2 = (stringaMac) => {
//   try {
//       //console.log(stringaMac);
//       const hash = createHash('sha1');
//       hash.update(stringaMac);
//       const hex = hash.digest('hex');
//       return hex.toUpperCase();
//   } catch (error) {
//       //console.log(error);
//   }
// };

  function hashMac(stringaMac: string, callback: (error: Error | null, hashedString: string) => void) {
    crypto.subtle.digest('SHA-1', new TextEncoder().encode(stringaMac))
      .then((digest) => {
        // Convert digest to hex string
        const hex = Array.from(new Uint8Array(digest))
          .map((b) => b.toString(16).padStart(2, '0'))
          .join('');
        // Invoke the callback with the hashed string
        callback(null, hex.toUpperCase());
      })
      .catch((error) => {
        // Invoke the callback with the error
        callback(error, null);
      });
  }

  // export function hashMac_3(stringToHash: string): string {
  //   try {
  //     // Create a SHA-1 hash object using the crypto module
  //     const hash = createHash("sha1").update(stringToHash, "utf8").digest("hex");
  
  //     return hash;
  //   } catch (error) {
  //     throw new Error(`Error hashing string: ${error.message}`);
  //   }
  // }

  //export async function decodicaEsitoPagamento(paramFromRequest: string): Promise<string> {
    export const decodicaEsitoPagamento = async (paramFromRequest: string) => {
    // Chiave segreta (presa nell'area di test)
    const CHIAVESEGRETA = "9C17HSIV251RL3T7AQJRRG22R74P5XQA";
    let  paramMap = null
    
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('esito');

    try {
    paramMap = urlParams //new Map(JSON.parse(paramFromRequestWithoutQuery));
    // Use data in paramMap and access query parameters using queryString.get('ordine')
    } catch (error) {
    // Handle parsing error
    }
  
    // Calcolo MAC con i parametri in ingresso
    const stringaMac = `codTrans=${paramMap.get("codTrans")}`
      + `esito=${paramMap.get("esito")}`
      + `importo=${paramMap.get("importo")}`
      + `divisa=${paramMap.get("divisa")}`
      + `data=${paramMap.get("data")}`
      + `orario=${paramMap.get("orario")}`
      + `codAut=${paramMap.get("codAut")}`
      + CHIAVESEGRETA;

    // const setmac = await checkValueMac(stringaMac);
    // //console.log(setmac)
    // const macCalculated = await hashMac_2(stringaMac); //hashMac_3(stringaMac);//getValueMac(stringaMac)//hashMac(stringaMac);

  
    try {
      // if (macCalculated !== paramMap.get("mac")) {
      //   return `Errore MAC: ${macCalculated} non corrisponde a ${paramMap.get("mac")}`;
      // }
  
      const esito = paramMap.get("esito");
      const messaggioErrore = paramMap.get("messaggio");
  
      if (esito === "OK") {
        const ordineIdString = paramMap.get("ordine");
        try {
          const ordineId = parseInt(String(ordineIdString));
          const ordine =  cercaOridineId(ordineId);
  
          if (ordine) {
            //inviaEmailDettagliOrdine(ordine);
  
            return "OK";
          } else {
            return `Ordine non trovato per l'id: ${ordineId}`;
          }
        } catch (e) {
          return `Errore durante la conversione dell'id dell'ordine`;
        }
      } else if (esito === "KO" && String(messaggioErrore)?.trim() === "Auth. Denied") {
        return `La transazione è stata rifiutata per autenticazione negata. Descrizione errore: ${paramMap.get("messaggio")}`;
      } else if (esito === "KO" && String(messaggioErrore)?.trim() === "Expired Card") {
        return `La transazione è stata rifiutata perchè la carta è scaduta. ${paramMap.get("messaggio")}`;
      } else if (esito === "KO" && String(messaggioErrore)?.trim() === "Trestricted Card") {
        return `La transazione è stata rifiutata perchè la carta è riservata ${paramMap.get("messaggio")}`;
      } else if (esito === "KO" && String(messaggioErrore)?.trim() === "Invalid Merchant") {
        return `commerciante non valido ${paramMap.get("messaggio")}`;
      } else if (esito === "KO" && String(messaggioErrore)?.trim() === "transaction Not Permitted") {
        return `transazione non consentita ${paramMap.get("messaggio")}`;
      } else if (esito === "KO" && String(messaggioErrore)?.trim() === "Not Sufficient Funds") {
        return `fondi insufficienti ${paramMap.get("messaggio")}`;
      } else if (esito === "KO" && String(messaggioErrore)?.trim() === "Incorret PIN") {
        return `Pin non corretto ${paramMap.get("messaggio")}`;
      } else if (esito === "KO" && String(messaggioErrore)?.trim() === "No Card Record") {
        return `nessun registro della carta ${paramMap.get("messaggio")}`;
      } else if (esito === "KO" && String(messaggioErrore)?.trim() === "Technical Problem") {
        return `problema tecnico ${paramMap.get("messaggio")}`;
      } else if (esito === "KO" && String(messaggioErrore)?.trim() === "Host Not Found") {
        return `Host non trovato ${paramMap.get("messaggio")}`;
      } else {
        return `La transazione ${paramMap.get("codTrans")} è stata rifiutata ${paramMap.get("messaggio")}`;
      }
    } catch (e) {
      return `Errore generico`;
    }
  }
  


//   export async function esito(paramFromRequest: Map<string, string>): Promise<string> {
//     // Chiave segreta (presa nell'area di test)
//     const CHIAVESEGRETA = "9C17HSIV251RL3T7AQJRRG22R74P5XQA"//requestNexi.getChiaveSegreta(); // Sostituire con il valore fornito da Nexi
  
//     // Calcolo MAC con i parametri in ingresso
//     const stringaMac = `codTrans=${paramFromRequest.get('codTrans')}`
//       + `esito=${paramFromRequest.get('esito')}`
//       + `importo=${paramFromRequest.get('importo')}`
//       + `divisa=${paramFromRequest.get('divisa')}`
//       + `data=${paramFromRequest.get('data')}`
//       + `orario=${paramFromRequest.get('orario')}`
//       + `codAut=${paramFromRequest.get('codAut')}`
//       + CHIAVESEGRETA;
  
//     try {
//       const macCalculated = await hashMac(stringaMac);
//       paramFromRequest.set('mac', macCalculated);
  
//       if (macCalculated !== paramFromRequest.get('mac')) {
//         return `Errore MAC: ${macCalculated} non corrisponde a ${paramFromRequest.get('mac')}`;
//       }
  
//       const esito = paramFromRequest.get('esito');
//       const messaggioErrore = paramFromRequest.get('messaggio');
  
//       if (esito === 'OK') {
//         const ordineIdString = paramFromRequest.get('ordine');
//         try {
//           const ordineId = parseInt(ordineIdString);
//           const ordine = await cercaOridineId(ordineId);
  
//           if (ordine) {
//             //inviaEmailDettagliOrdine(ordine);
  
//             return `La transazione è avvenuta con successo. Descrizione : ${paramFromRequest.get('messaggio')}`;
//           } else {
//             return `Ordine non trovato per l'id: ${ordineId}`;
//           }
//         } catch (e) {
//           return `Errore durante la conversione dell'id dell'ordine`;
//         }
//       } else if (esito === 'KO' && messaggioErrore?.trim() === 'Auth. Denied') {
//         return `La transazione è stata rifiutata per autenticazione negata. Descrizione errore: ${paramFromRequest.get('messaggio')}`;
//       } else if (esito === 'KO' && messaggioErrore?.trim() === 'Expired Card') {
//         return `La transazione è stata rifiutata perchè la carta è scaduta. ${paramFromRequest.get('messaggio')}`;
//       } else if (esito === 'KO' && messaggioErrore?.trim() === 'Trestricted Card') {
//         return `La transazione è stata rifiutata perchè la carta è riservata ${paramFromRequest.get('messaggio')}`;
//       } else if (esito === 'KO' && messaggioErrore?.trim() === 'Invalid Merchant') {
//         return `commerciante non valido ${paramFromRequest.get('messaggio')}`;
//       } else if (esito === 'KO' && messaggioErrore?.trim() === 'transaction Not Permitted') {
//         return `transazione non consentita ${paramFromRequest.get('messaggio')}`;
//       } else if (esito === 'KO' && messaggioErrore?.trim() === 'Not Sufficient Funds') {
//         return `fondi insufficienti ${paramFromRequest.get('messaggio')}`;
//       } else if (esito === 'KO' && messaggioErrore?.trim() === 'Incorret PIN') {
//         return `Pin non corretto ${paramFromRequest.get('messaggio')}`;
//       } else if (esito === 'KO' && messaggioErrore?.trim() === 'No Card Record') {
//         return `nessun registro della carta ${paramFromRequest.get('messaggio')}`;
//       } else if (esito === 'KO' && messaggioErrore?.trim() === 'Technical Problem') {
//         return `problema tecnico ${paramFromRequest.get('messaggio')}`;
//       } else if (esito === 'KO' && messaggioErrore?.trim() === 'Host Not Found') {
//         return `Host non trovato ${paramFromRequest.get('messaggio')}`;
//       } else {
//         return `La transazione ${paramFromRequest.get('codTrans')} è stata rifiutata ${paramFromRequest.get('messaggio')}`;
//       }
//     } catch (e) {
//       return `Errore generico`;
//     }
// }
  
