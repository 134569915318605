import { APIService } from "services";
import { parseJwtToken } from "./tokenService";

export const getPiano_Estrazioni = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllPiano_Estrazioni(pageNo,pageSize);
    }
    else{
        try {
            res = await searchPiano_Estrazioni(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}

export const getPiano_Estrazioni_Utente = async (pageNo,pageSize,search) => {
    let idUtente = 0;
    const token = localStorage.getItem('token');
    const jwtToken = parseJwtToken(token);
  if(jwtToken)
    {
        idUtente = jwtToken.data.id;
    }
    let res;
    if(search.length===0) {
        //console.log(idUtente)
        res = await getPiano_Estrazioni_By_User(Number(idUtente));
    }
    else{
        try {
            res = await searchPiano_Estrazioni(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllPiano_Estrazioni = (pageno,pagesize) => {
return APIService.api().get(`/piano_estrazioni/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOnePiano_Estrazioni = (id) => {
return APIService.api().get(`/piano_estrazioni/read_one.php?id=${id}`)
}
export const searchPiano_Estrazioni = (key,pageno,pagesize) => {
return APIService.api().get(`/piano_estrazioni/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addPiano_Estrazioni = (data) => {
return APIService.api().post(`/piano_estrazioni/create.php`,data)
}
export const updatePiano_Estrazioni = (data) => {
return APIService.api().post(`/piano_estrazioni/update.php`,data)
}
export const deletePiano_Estrazioni = (id) => {
return APIService.api().post(`/piano_estrazioni/delete.php`,{id:id})
}
export const getElencoImpreseFile= (dati_ricerca) => {
    return APIService.api().post(`/documenti/visualizza_elenco_impresa.php`, dati_ricerca);
    }    

export const getPiano_Estrazioni_By_User = (id) => {
    return APIService.api().get(`/piano_estrazioni/read_one_by_id_user.php?id_cliente=${id}`)
    }