import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetAssistenzaToInit, setAssistenzaMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteAssistenza } from "services/assistenzaService";
import moment from "moment";
import { parseJwtToken } from "services/tokenService";
type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey) => void;
};
export const AssistenzaTable: React.FC<Props> = ({ hideShowForm, handleRowEdit, getData }) => {
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);
    const rData = useSelector((state: RootState) => state.assistenza);
    const token = localStorage.getItem('token');
    const jwtToken = parseJwtToken(token);



    const handleSearch = () => {
        if (search.length > 0) {
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
        }
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        await getData(page, newPerPage, '');
    }
    const handlePageChange = (page) => {
        getData(page, rData.pageSize, '');
    };
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    }
    useEffect(() => {
        if (rData && rData.list && rData.list.length === 0) {
            dispatch(resetAssistenzaToInit());
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
        }
    },[rData.list.length])
    
    const handleReset = () => {
        setSearch('');
        dispatch(resetAssistenzaToInit());
        getData(Constant.defaultPageNumber, rData.pageSize, '');
    }
    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deleteAssistenza(rowData.id);
            if (response) {
                dispatch(resetAssistenzaToInit());
                dispatch(setAssistenzaMessage("Cancellazione avvenuta con successo"));
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                setShowDelete(false);
            } else {
                dispatch(setAssistenzaMessage("Abbiamo rilevato un errore, riprovare!"));
            }
        }
    }

    const handleRowSelection = (row) => {
        //console.log(row); // Row Selection Functionality can be written here
    }
    const handleAddButtonClick = () => {
        dispatch(setAssistenzaMessage(''));
        hideShowForm('add');
    }
   

    const handleStatoAssistenza = (row) => {
        if(String(row.stato) === '0'){
            return (
                <>
                <i className="fa-solid fa-envelope-circle-check icon-size-normal color-gray-pg"></i>
                <p className="font-12 color-gray-pg text-center">inviata</p>
                </>
            
        ) 
        }else if(String(row.stato) === '3'){
            return (
                <>
                <i className="fa fa-check icon-size-normal  color-blue"></i>
                <p className="font-12 color-blue text-center">risposta</p>
                </>
            
        )
        }else{
            return (<i className="fa fa-times icon-size-normal  color-icon-notfound-gray"></i>)
        }
    }

    const handleDelete = (row) =>{

        // return <i onClick={() => handleRowDeleteClick(row)} className="fa-solid fa-trash icon-size-normal color-icon-notfound-gray"></i>

    }

    const handleUpdate = (row) =>{

        return <i onClick={() => handleRowEdit(row)} className="fa fa-pencil icon-size-normal color-blue"></i>

    }


    const valoreDato = (dato) => {
        if(dato == null || dato === ""){
            return "Dato non fornito"
        }else{
            return dato
        }
    }

    const columns = [
       {name: 'id', selector: row => row.id, sortable: true},
{name: 'id_utente', selector: row => row.id_utente, sortable: true},
{name: 'data_richiesta', selector: row => row.data_richiesta, sortable: true},
{name: 'stato', selector: row => row.stato, sortable: true},
{name: 'nome', selector: row => row.nome, sortable: true},
{name: 'cognome', selector: row => row.cognome, sortable: true},
{name: 'azienda', selector: row => row.azienda, sortable: true},
{name: 'email', selector: row => row.email, sortable: true},
{name: 'telefono', selector: row => row.telefono, sortable: true},
{name: 'descrizione', selector: row => row.descrizione, sortable: true},
{name: 'risposta', selector: row => row.risposta, sortable: true},

        ,{
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowEdit(row)}>Edit</Button>,
        },
        {
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowDeleteClick(row)}>Delete</Button>,
        },
    ];
    return (
        <>
        <div className="d-flex flex-column" >
            <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
                    <div className="col-sm-8">
                        <h4>Assistenza</h4>
                    </div>
                    <div className="col-sm-4 text-align-right">
                        
                        <>
                        
                        <Button variant="success" className="" onClick={() => handleAddButtonClick()}><i className="fa-solid fa-plus"></i> Inserisci</Button>

                        </>
                        {"  "}
                        <>
                        <Button variant="info" className="" onClick={() => handleReset()}><i className="fa fa-refresh"></i> Aggiorna ({rData.totalCount})</Button>
                        </>
                                        
                    </div>
                    {/* <div className="col-sm-2 text-align-right">
                        
                        <>
                        <Button variant="info" className="" onClick={() => handleReset()}><i className="fa fa-refresh"></i> Aggiorna ({rData.totalCount})</Button>
                        </>
                                        
                    </div> */}
            </Row>
        </div>
        <div>
            <Col className="ml-auto padding-bottom20" md={4} xs={12} xl={4}>
                    <InputGroup>
                        <Form.Control
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-search"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <Button disabled={search.length <= 2} variant="dark" className="rounded-0 rounded-right" id="button-search" onClick={handleSearch}>
                            Search
                        </Button>
                    </InputGroup>
                </Col>
        </div>
        <div className="d-flex flex-column" >
                
                {rData != null && rData.list.map((row, i) =>{
                    return(
                        <>
                        <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
                        <Col md={1} xs={12} xl={1} className="center-block-custom" >
                             <p className="align-center">{handleStatoAssistenza(row)}</p> 
                        </Col>
                        
                        <Col md={10} xs={12} xl={10}>
                            <Row>
                                <div className="col-sm-6 color-gray-pg">Data Richiesta: <p><strong className="color-blue">{moment(row.data_richiesta).format('DD/MM/YYYY HH:mm:ss')}</strong></p></div>
                                <div className="col-sm-6 color-gray-pg">Azienda: <p><strong className="color-blue">{valoreDato(row.azienda)}</strong></p></div>
                                <div className="col-sm-12 color-gray-pg">Descrizione: <p><strong className="color-blue">{valoreDato(row.descrizione)}</strong></p></div>
                                {
                                    row.stato == 3 && 
                                    <div className="col-sm-6 color-gray-pg">Risposta: <p><strong className="color-blue">{valoreDato(row.risposta)}</strong></p></div>
                                }
                            </Row>
                        </Col>
                        <Col md={1} xs={12} xl={1} className="center-block-custom" >
                             {/* <p className="align-center">{handleDelete(row)}</p> */}
                             {  jwtToken.data.id == 1 &&
                             <p className="align-center">{handleUpdate(row)}</p>
                             }

                                 
                        </Col>
                        
                        </Row>
                        
                    </>

                    )
                })
                
                }
                <ConfirmationModal buttonNegative="Annulla" buttonPositive="Conferma" title="Cancella Richiesta" show={showDelete} body={"Sei Sicuro i voler eliminare questa richiesta?"} onNegative={() => setShowDelete(false)} onPositive={handleServerDelete} />
            </div>
        </>
        // <Card className="shadow mb-4">
        //     <Card.Header className="py-3">
        //         <h6 className="m-0 font-weight-bold text-primary">Assistenza List ({rData.totalCount})
        //             <Button variant="light" className="btn-circle btn-sm ml-2" onClick={handleReset}><i className="fa fa-refresh"></i></Button>
        //             <Button className="btn-icon-split float-right" onClick={handleAddButtonClick}>
        //                 <span className="icon text-white-50">
        //                     <i className="fas fa-add"></i>
        //                 </span>
        //                 <span className="text">Add New</span>
        //             </Button></h6>

        //     </Card.Header>
        //     <Card.Body>
        //         <Col className="ml-auto" md={3} xs={12} xl={3}>
        //             <InputGroup>
        //                 <Form.Control
        //                     placeholder="Search"
        //                     aria-label="Search"
        //                     aria-describedby="basic-search"
        //                     onChange={(e) => setSearch(e.target.value)}
        //                 />
        //                 <Button disabled={search.length <= 2} variant="dark" className="rounded-0 rounded-right" id="button-search" onClick={handleSearch}>
        //                     Search
        //                 </Button>
        //             </InputGroup>
        //         </Col>
        //         <div className="table-responsive">
        //             <DataTable
        //                 selectableRows={true}
        //                 onSelectedRowsChange={handleRowSelection}
        //                 paginationPerPage={Constant.defaultPageNumber}
        //                 paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
        //                 columns={columns} data={rData.list}
        //                 onChangeRowsPerPage={handlePerRowsChange}
        //                 paginationTotalRows={rData.totalCount}
        //                 className="table table-bordered"
        //                 pagination
        //                 paginationServer
        //                 onChangePage={handlePageChange}></DataTable>
        //         </div>
        //     </Card.Body>
        //     <ConfirmationModal buttonNegative="Cancel" buttonPositive="Delete" title="Delete Confirmation" show={showDelete} body={"Are you sure?"} onNegative={() => setShowDelete(false)} onPositive={handleServerDelete} />
        // </Card>
    );
}

