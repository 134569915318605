import { APIService } from "services";

export const getStorico_Pacchetti_Abbonamento = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllStorico_Pacchetti_Abbonamento(pageNo,pageSize);
    }
    else{
        try {
            res = await searchStorico_Pacchetti_Abbonamento(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllStorico_Pacchetti_Abbonamento = (pageno,pagesize) => {
return APIService.api().get(`/storico_pacchetti_abbonamento/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}

export const getAllStorico_Pacchetti_Abbonamento_id_user = (pageno,pagesize, id) => {
    return APIService.api().get(`/storico_pacchetti_abbonamento/read_id_user.php?pageno=${pageno}&pagesize=${pagesize}&rif=${id}`)
}


export const getOneStorico_Pacchetti_Abbonamento = (id) => {
return APIService.api().get(`/storico_pacchetti_abbonamento/read_one.php?id=${id}`)
}
export const searchStorico_Pacchetti_Abbonamento = (key,pageno,pagesize) => {
return APIService.api().get(`/storico_pacchetti_abbonamento/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addStorico_Pacchetti_Abbonamento = (data) => {
return APIService.api().post(`/storico_pacchetti_abbonamento/create.php`,data)
}
export const updateStorico_Pacchetti_Abbonamento = (data) => {
return APIService.api().post(`/storico_pacchetti_abbonamento/update.php`,data)
}
export const deleteStorico_Pacchetti_Abbonamento = (id) => {
return APIService.api().post(`/storico_pacchetti_abbonamento/delete.php`,{id:id})
}
