import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setAssistenzaMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { Constant } from "template/Constant";
import * as yup from 'yup';
import { addAssistenza, inserisciAssistenza, updateAssistenza } from "services/assistenzaService";
import { parseJwtToken } from "services/tokenService";
import { getOneWs_Login } from "services/ws_loginService";
import moment from "moment";
import { sendMailAssistenza } from "services/mailService";

import img_jmyimprese from "../asset/Intersect.png"
import img_logotecno from "../asset/TecnoinnovisLogo.png"
import img_logomyimprese from "../asset/Logomyimprese.png"
import img_logomyvisure from "../asset/logo_myvisure.png"
import img_logopaypal from "../asset/logo_pp.png"
import img_logonexi from "../asset/logo_n.png"
import img_logomyimpresawhite from "../asset/logo_mi_white.png"
import logo from "../asset/logo_myimprese.png"
import { useNavigate } from "react-router";
import NavigationMenu from "template/NavigationMenu";
import FooterPage from "template/FooterPage";

type Props = {
    row?: any,
    // hideShowForm: (actionName) => void;
    // getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const AssistenzaUtente: React.FC<Props> = () => {
    const dispatch = useAppDispatch();
    const iValue={id:'',id_utente:'',nome:'',cognome:'',azienda:'',email:'',telefono:'',descrizione:'', acceptTerms: false};
    //let initialValues = iValue
   
    

    const token = localStorage.getItem('token');
    const jwtToken = parseJwtToken(token);
    var idUtente = 0
    let iValueUser = {
        id: '',
        id_utente: '',
        nome: '',
        cognome: '',
        azienda: '',
        email: '',
        telefono: '',
        descrizione: '',
        acceptTerms: false
    }
    const iValueEmpty = {
        id: '',
        id_utente: '',
            nome: '',
            cognome: '',
            azienda: '',
            email: '',
            telefono: '',
            descrizione: '',
            acceptTerms:false
    }

    const[datiLoad, setDatiLoad] = useState(null);

    const[id_utente, setIdUtente] = useState('');
    const[nome, setNome] = useState('');
    const[cognome, setCognome] = useState('');
    const[azienda, setAzienda] = useState('');
    const[email, setEmail] = useState('');
    const[telefono, setTelefono] = useState('');
    const[descrizione, setDescrizione] = useState('');

    const[messaggio, setMessaggio] = useState("")
    const[esito, setEsito] = useState(false)

    


    useEffect(() => {
        
        if(jwtToken && jwtToken.data != null ){
            idUtente = jwtToken.data.id;
    
            if(datiLoad == null){
                getOneWs_Login(idUtente).then((response) => {
                    if (response && response.data && response.data.document) {
                      //console.log(response);
                      const iValueUser_set = {
                        id: '',
                        id_utente: response.data.document.id,
                        nome: response.data.document.nome_referente,
                        cognome: response.data.document.cognome_referente,
                        azienda: response.data.document.nome_azienda,
                        email: response.data.document.email,
                        telefono: response.data.document.telefono,
                        descrizione: '',
                        acceptTerms:false
                    }
        
                    setAzienda(response.data.document.nome_azienda)
                    setEmail(response.data.document.email)
                    setTelefono(response.data.document.telefono)
                    iValueUser = iValueUser_set;
                    setDatiLoad(iValueUser);
    
                    }else{
                        iValueUser = iValueEmpty
                        setDatiLoad(iValueUser);
                    }
                  })
            }
            
        }else{
            iValueUser = iValueEmpty
        }
      }, [datiLoad]);
    
    const formik = useFormik({
        initialValues: {
            id_utente: 0,
            nome: "",
            cognome: "",
            email: "",
            azienda:"",
            telefono:"",
            stato: 0,
            data_richiesta: moment(),
            descrizione:"",
            acceptTerms: false
        },
        onSubmit: async (values) => {
            setEsito(false)

            if(jwtToken && jwtToken.data != null){
                values.id_utente =jwtToken.data.id
                values.nome = datiLoad.nome
                values.cognome = datiLoad.cognome
                values.email = datiLoad.email
                values.telefono = datiLoad.telefono
                values.azienda = datiLoad.azienda
                values.acceptTerms = datiLoad.acceptTerms
            }
                const response = await inserisciAssistenza(values);
                if (response) {
                    setMessaggio("Richiesta inserita con successo")
                    setEsito(true)

                    
                    values.nome = ""
                    values.cognome = ""
                    values.email = ""
                    values.telefono = ""
                    values.azienda = ""
                    values.descrizione = ""
                    values.acceptTerms = false

                } else {
                    //dispatch(setAssistenzaMessage("Some error occured!"));
                    setMessaggio("Richiesta non inserita con successo")
                    setEsito(true)
                }
        },
        
        validationSchema: yup.object({
            //id_utente: yup.number().nullable(),
            nome: yup.lazy((value) => {
                return jwtToken.data == null ? yup.string().required('Campo Obbligatorio') : yup.string();
            }),
            cognome: yup.lazy((value) => {
                return jwtToken.data == null ? yup.string().required('Campo Obbligatorio') : yup.string();
            }),
            azienda: yup.lazy((value) => {
                return jwtToken.data == null ? yup.string().required('Campo Obbligatorio') : yup.string();
            }),
            email:yup.lazy((value) => {
                return jwtToken.data == null ? yup.string().required('Campo Obbligatorio') : yup.string();
            }),
            telefono:yup.lazy((value) => {
                return jwtToken.data == null ? yup.string().required('Campo Obbligatorio') : yup.string();
            }),
            descrizione: yup.string().required('Campo Obbligatorio'),
            acceptTerms: yup.boolean().oneOf([true], 'Devi accettare le condizioni del servizio'),
            //stato: yup.number().required('stato is required'),
        }),
    });

    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.toggle('bg-gradient-primary', true);
    }, [])



    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLoging = () => {
    navigate('/', { replace: true });
  }

  const MenuItems = () => (

    <>
      <p onClick={()=>handleLoging()} className="font-menu cursor-pointer hover:text-blue-500 transition-colors">Home</p>
    </>
  );

  const handleNewRequest = () =>{
    setEsito(false)
    setNome("")
    setCognome("")
    setAzienda("")
    setEmail("")
    setTelefono("")
    setDescrizione("")
  }

    return (
        <>
        <div className="">
            <NavigationMenu isLoading={false}/>
            {/* <div className="nav-desktop row menu-basic align-items-center px-custom">
                <div className="col-sm-6">
                    <img src={logo} alt="" className="logo-public" />
                </div>
                <div className="col-sm-6">
                    <div className="d-flex justify-content-end gap-3">
                        <p  onClick={()=>handleLoging()} className="font-menu">Home</p>
                    </div>
                </div>
            </div>
            <div className="nav-mobile row menu-basic align-items-center px-custom relative paddind-lr-m-15 min-height-auto">
                <div className='row full-width'>
                    <div className="col-sm-6 dim-custom-80">
                        <img src={logo} alt="Logo" className="logo-public" />
                    </div>
                    <div className="col-sm-6 dim-custom-20 flex text-center">
                        <i className="fa fa-bars icon-bar-sm" onClick={toggleMobileMenu}></i>
                    </div>
                </div>
                {isMobileMenuOpen && (
                <div className="fixed inset-0 bg-white z-40 pt-20">
                    <div className="flex flex-col items-center space-y-6">
                        <MenuItems />
                    </div>
                </div>
                )}
            </div> */}
            <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-12 col-md-12">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">Assistenza</h1>
                                            {esito && 
                                                <p className='icon-confirm color-blue font-24'><i className="fa fa-check-circle"></i> {messaggio}</p>
                                            }
                                        </div>
                                        {!esito && (
                                        
                                        <Form onSubmit={formik.handleSubmit}>
  {(jwtToken == null || jwtToken.data == null) && datiLoad == null && (
    <>
      <Row>
        <Col md={6}>
          <Form.Group>
            <label className="form-control-label no-padding">Nome</label>
            <Form.Control
              type="text"
              name="nome"
              className="form-control"
              value={formik.values.nome}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.nome && !!formik.errors.nome}
              isValid={!!formik.touched.nome && !formik.errors.nome}
            />
            {formik.errors.nome && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.nome}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <label className="form-control-label no-padding">Cognome</label>
            <Form.Control
              type="text"
              name="cognome"
              className="form-control"
              value={formik.values.cognome}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.cognome && !!formik.errors.cognome}
              isValid={!!formik.touched.cognome && !formik.errors.cognome}
            />
            {formik.errors.cognome && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.cognome}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group>
            <label className="form-control-label no-padding">Azienda</label>
            <Form.Control
              type="text"
              name="azienda"
              className="form-control"
              value={formik.values.azienda}
              onChange={formik.handleChange}
              isInvalid={!!formik.touched.azienda && !!formik.errors.azienda}
              isValid={!!formik.touched.azienda && !formik.errors.azienda}
            />
            {formik.errors.azienda && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.azienda}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <label className="form-control-label no-padding">Email</label>
            <Form.Control
              type="text"
              name="email"
              className="form-control"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.email && !!formik.errors.email}
              isValid={!!formik.touched.email && !formik.errors.email}
            />
            {formik.errors.email && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group>
            <label className="form-control-label no-padding">Telefono</label>
            <Form.Control
              type="text"
              name="telefono"
              className="form-control"
              value={formik.values.telefono}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.telefono && !!formik.errors.telefono}
              isValid={!!formik.touched.telefono && !formik.errors.telefono}
            />
            {formik.errors.telefono && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.telefono}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
    </>
  )}
  {jwtToken != null && jwtToken.data != null && jwtToken.data.id != null && datiLoad != null && (
    <>
      <p>
        Autenticato come: <h6><strong>{datiLoad.azienda}</strong></h6>
      </p>
    </>
  )}
  <Form.Group>
    <label className="form-control-label no-padding">Descrizione</label>
    <Form.Control
      as="textarea"
      rows={5}
      name="descrizione"
      className="form-control"
      value={formik.values.descrizione}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      isInvalid={!!formik.touched.descrizione && !!formik.errors.descrizione}
      isValid={!!formik.touched.descrizione && !formik.errors.descrizione}
    />
    {formik.errors.descrizione && (
      <Form.Control.Feedback type="invalid">
        {formik.errors.descrizione}
      </Form.Control.Feedback>
    )}
  </Form.Group>
  <Form.Group>
    <p>
      Confermando i dati autorizzo Tecno Innovis Srl al trattamento dei dati personali, anche con modalità automatizzate e con l'ausilio di società terze, finalizzato all'invio di comunicazioni,
      materiale informativo e promozionale relative esclusivamente al prodotto o servizio qui promosso come previsto dalla nostra{' '}
      <a
        style={{
          fontWeight: 'bold',
          color: '#0E1449',
          textDecoration: 'underline',
        }}
        href="/privacy-policy"
      >
        Privacy Policy
      </a>
    </p>
    <Form.Check
      type="checkbox"
      name="acceptTerms"
      label={
        <span>
          Accetti le condizioni del servizio?{' '}
          <a
            href="/termini-condizioni"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontWeight: 'bold',
              color: '#0E1449',
              textDecoration: 'underline',
            }}
          >
            Leggi qui
          </a>
        </span>
      }
      onChange={formik.handleChange}
      isInvalid={!!formik.errors.acceptTerms}
      className="padding-t-5"
    />
    {formik.errors.acceptTerms && (
      <Form.Control.Feedback type="invalid">
        {formik.errors.acceptTerms}
      </Form.Control.Feedback>
    )}
  </Form.Group>
  <Form.Group>
  <div className="text-center-ass">
    <Button type="submit" className="btn-invio-assistenza">
      Invia
    </Button>
  </div>
</Form.Group>

</Form>
)}



                                        {esito &&
                                         (
                                            <p>
                                                <button onClick={handleNewRequest} type="submit" className="btn-assistenza">Inserisci nuova Richiesta</button>
                                            </p>
                                        )
                                        }
                                           
                                        
                                        

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
        <FooterPage />
        {/* <div className="row box-footer-bg">
        <div className="col-sm-12">
            <div className="row box-footer-space">
                <div className="col-sm-4">
                    <p className="box-footer-col1-text">MyImprese è un servizio</p>
                    <img src={img_logotecno} className="box-footer-logo-tecno space-sm" />

                </div>
                <div className="col-sm-4">
                        <p className="box-footer-link"><a href="/privacy-policy" className="box-footer-link-color">Privacy Policy</a></p>
                        <p className="box-footer-link"><a href="/termini-condizioni" className="box-footer-link-color">Termini e Condizioni</a></p>
                        <p className="box-footer-link"><a href="/assistenza" className="box-footer-link-color">Contattaci</a></p>
                </div>
                <div className="col-sm-4">
                    <p className="box-footer-text-col3">Gestiamo pagamenti sicuri sulle piattaforme</p>
                    <p className="image-right"><img src={img_logopaypal} className="box-footer-logo-tecno box-footer-logo_1" /></p>
                    <p className="image-right"><img src={img_logonexi} className="box-footer-logo-tecno box-footer-logo_2" /></p>
                </div>

            </div>
        </div>
        
    </div>
    <div className="row box-copy">
        <div className="col-sm-12 p-top-20">
            <p className="t-align-center box-copy-text"><img src={img_logomyimpresawhite} className="box-copy-logo"/> - 2024</p>
        </div>
    </div> */}
    
        {/* <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-6 d-none d-lg-block bg-login-image" style={{ height: 280, margin : 'auto'}}></div>
                                <div className="col-lg-6">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">Benvenuto!</h1>
                                        </div>
        <Card className="shadow mb-4">
            <Card.Body>
                {esito &&    
                <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{messaggio}
                    <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setEsito(false)}>
                    <span aria-hidden="true">&times;</span>
                    </Button>
                </Alert>
                }
            
                <Form onSubmit={formik.handleSubmit}>
                {(jwtToken == null || jwtToken.data == null) && (datiLoad == null) && 
                <>
                <Form.Group>
<label className="form -control-label no-padding">Nome</label>
<Form.Control type="text" name="nome" className="form-control" value={formik.values.nome}
onChange={formik.handleChange}
onBlur={formik.handleBlur}
isInvalid ={!!formik.touched.nome && !!formik.errors.nome}
isValid ={!!formik.touched.nome && !formik.errors.nome}
></Form.Control>
{
    formik.errors.nome && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.nome}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label no-padding">cognome</label>
<Form.Control type="text" name="cognome" className="form-control" value={formik.values.cognome}
onChange={formik.handleChange}
onBlur={formik.handleBlur}
isInvalid ={!!formik.touched.cognome && !!formik.errors.cognome}
isValid ={!!formik.touched.cognome && !formik.errors.cognome}
></Form.Control>
{
    formik.errors.cognome && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.cognome}
    </Form.Control.Feedback>
)}
</Form.Group>
 <Form.Group>
<label className="form -control-label no-padding">azienda</label>
<Form.Control type="text" name="azienda" className="form-control" value={formik.values.azienda}
onChange={formik.handleChange}
//onBlur={formik.handleBlur}
isInvalid ={!!formik.touched.azienda && !!formik.errors.azienda}
isValid ={!!formik.touched.azienda && !formik.errors.azienda}
></Form.Control>
{
    formik.errors.azienda && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.azienda}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label no-padding">email</label>
<Form.Control type="text" name="email" className="form-control" value={formik.values.email}
onChange={formik.handleChange}
onBlur={formik.handleBlur}
isInvalid ={!!formik.touched.email && !!formik.errors.email}
isValid ={!!formik.touched.email && !formik.errors.email}
></Form.Control>
{
    formik.errors.email && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.email}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label no-padding">telefono</label>
<Form.Control type="text" name="telefono" className="form-control" value={formik.values.telefono}
onChange={formik.handleChange}
onBlur={formik.handleBlur}
isInvalid ={!!formik.touched.telefono && !!formik.errors.telefono}
isValid ={!!formik.touched.telefono && !formik.errors.telefono}
></Form.Control>
{
    formik.errors.telefono && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.telefono}
    </Form.Control.Feedback>
)}
</Form.Group>
                </>

} 
{jwtToken != null && jwtToken.data != null && jwtToken.data.id != null && datiLoad != null &&
<>
<p>Autenticato come: <h6><strong>{datiLoad.azienda}</strong></h6></p>

</>
}                
<Form.Group>
<label className="form -control-label no-padding">descrizione</label>
<Form.Control as="textarea" rows={5} name="descrizione" className="form-control" value={formik.values.descrizione}
onChange={formik.handleChange}
onBlur={formik.handleBlur}
isInvalid ={!!formik.touched.descrizione && !!formik.errors.descrizione}
isValid ={!!formik.touched.descrizione && !formik.errors.descrizione}
></Form.Control>
{
     formik.errors.descrizione  && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.descrizione}
    </Form.Control.Feedback>
)}

</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary" >Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
        <div className="copyright text-center my-auto">
                                            <span>Copyright &copy; elenco imprese 2022</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div> */}
    </>
    );
}

