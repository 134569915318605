import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetDati_UtenteToInit, setDati_UtenteMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteDati_Utente } from "services/dati_utenteService";
import { parseJwtToken } from "services/tokenService";
import { useNavigate } from "react-router";
type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey) => void;
};
export const Dati_UtenteTable: React.FC<Props> = ({ hideShowForm, handleRowEdit, getData }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);
    const rData = useSelector((state: RootState) => state.dati_utente);
    const token = localStorage.getItem('token');
    const jwtToken = parseJwtToken(token);
    const handleSearch = () => {
        if (search.length > 0) {
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
        }
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        await getData(page, newPerPage, '');
    }
    const handlePageChange = (page) => {
        getData(page, rData.pageSize, '');
    };
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    }
    useEffect(() => {
        if (rData && rData.list && rData.list.length === 0) {
            dispatch(resetDati_UtenteToInit());
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
        }
    },[rData.list, rData.list.length])
    const handleReset = () => {
        setSearch('');
        dispatch(resetDati_UtenteToInit());
        getData(Constant.defaultPageNumber, rData.pageSize, '');
    }
    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deleteDati_Utente(rowData.id);
            if (response) {
                dispatch(resetDati_UtenteToInit());
                dispatch(setDati_UtenteMessage("Dati Cancellati con Successo"));
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                setShowDelete(false);
            } else {
                dispatch(setDati_UtenteMessage("Dati non cancellati. Rilevato errore riprovare"));
            }
        }
    }

    const handleRowSelection = (row) => {
        //console.log(row); // Row Selection Functionality can be written here
    }
    const handleAddButtonClick = () => {
        dispatch(setDati_UtenteMessage(''));
        hideShowForm('add');
    }

    const handleProfilo = () => {
        navigate("/profilo/", {replace: true})
    }

    const columns = [
       {name: 'id', selector: row => row.id, sortable: true},
{name: 'id_utente', selector: row => row.id_utente, sortable: true},
{name: 'codice_univoco', selector: row => row.codice_univoco, sortable: true},
{name: 'pec', selector: row => row.pec, sortable: true},
{name: 'indirizzo', selector: row => row.indirizzo, sortable: true},
{name: 'citta', selector: row => row.citta, sortable: true},
{name: 'provincia', selector: row => row.provincia, sortable: true},

        ,{
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowEdit(row)}>Edit</Button>,
        },
        {
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowDeleteClick(row)}>Delete</Button>,
        },
    ];

    const valoreDato = (dato) => {
        if(dato == null || dato === ""){
            return "Dato non fornito"
        }else{
            return dato
        }
    }


    return (
        <>
            <div className="d-flex flex-column" >
            <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
                    <div className="col-sm-9">
                        <h4>Dati Fatturazione</h4>
                    </div>
                    <div className="col-sm-3 text-align-right">
                        <Button variant="info" className="" onClick={() => handleAddButtonClick()}><i className="fa-solid fa-plus"></i> Aggiungi</Button>
                        <span>{"   "}</span>
                        <Button variant="success" className="" onClick={() => handleProfilo()}><i className="fa-solid fa-user"></i> Profilo</Button>                 
                    </div>
            </Row>
            </div>
            <div>
                {jwtToken != null && jwtToken.data.id == 1 &&
                <Col className="ml-auto" md={3} xs={12} xl={3}>
                    <InputGroup>
                        <Form.Control
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-search"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <Button disabled={search.length <= 2} variant="dark" className="rounded-0 rounded-right" id="button-search" onClick={handleSearch}>
                            Search
                        </Button>
                    </InputGroup>
                </Col>
                }
                
                <div className="d-flex flex-column" >
                
                {rData != null && rData.list.map((utente, i) =>{
                    return(
                        <>
                        <Row className="shadow box-ricerca-elenco row heigth-max margin-row">

                        
                        <Col md={10} xs={12} xl={10}>
                            <Row>
                                <div className="col-sm-6">Indirizzo: <p><strong>{valoreDato(utente.indirizzo)}</strong></p></div>
                                <div className="col-sm-6">Citta: <p><strong>{valoreDato(utente.citta)} {"(" + valoreDato(utente.provincia)+ ")"}</strong></p></div>
                                <div className="col-sm-6">Pec: <p><strong>{valoreDato(utente.pec)}</strong></p></div>
                                <div className="col-sm-6">Codice univoco: <p><strong>{valoreDato(utente.codice_univoco)}</strong></p></div>
                            </Row>
                        </Col>
                        <Col md={2} xs={12} xl={2} className="center-block-custom" >
                            <div className="padding-bottom20 text-align-right">
                                <Button variant="success" className="" onClick={() => handleRowEdit(utente)} ><i className="fa-solid fa-pencil"></i> Modifica</Button>
                            </div>

                            <div className="text-align-right">
                                <Button variant="danger" className="text-align-right"  onClick={() => handleRowDeleteClick(utente)}><i className="fa-solid fa-trash"></i> Elimina</Button>
                            </div>
                            
                                            
                        </Col>
                        </Row>
                        
                    </>

                    )
                })
                
                }
                
                </div>
            </div>
            <ConfirmationModal buttonNegative="Annulla" buttonPositive="Conferma" title="Elimina Dati Utente" show={showDelete} body={"Sei sicuro di voler eliminare i dati?"} onNegative={() => setShowDelete(false)} onPositive={handleServerDelete} />
        </>
    );
}

