import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { array } from 'yup';

type Props = {
    show: boolean;
    title: ReactNode;
    body: ReactNode;
    buttonPositive: ReactNode;
    buttonNegative: ReactNode;
    disableButton?: boolean;
    onPositive: () => void;
    onNegative: () => void;
};

const ConfirmationOrdine: FC<Props> = ({
    show,
    title,
    body,
    buttonPositive,
    buttonNegative,
    onPositive,
    onNegative,
    disableButton,
}) => {
    const[accettaTermini , setAccettaTermini] = useState(false)
    const[disableConfirm , setDisableConfirm] = useState(true)

    useEffect(() => {
        if (!accettaTermini) {
            setDisableConfirm(true)
          } else {

            setDisableConfirm(false)
          }
    }, [accettaTermini, disableConfirm])

    const handleCheckboxChange = (e) => {
        const { checked } = e.target; // Ottieni lo stato (checked) dal target dell'evento
        const errorDiv = document.getElementById("acceptTermsError");
    
        setAccettaTermini(checked)

        
    
        //console.log(checked)
      
        if (!checked) {
          errorDiv.textContent = "Devi accettare le condizioni del servizio.";
          errorDiv.style.display = "block";
        } else {
          errorDiv.textContent = "";
          errorDiv.style.display = "none";
          setDisableConfirm(false)
        }
      };
    if(body != null){
        //console.log(body)
        let pacchetti = [];
        pacchetti = body["datiPacchetto"];
            return (
                <Modal size="lg" show={show} onHide={() => onNegative()} dialogClassName="modal-dialog-centered">
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                                <div className='col-sm-12'>
                                    <p className='icon-confirm color-blue'><i className="fa fa-check-circle"></i></p>
                                </div>
                                <div className='col-sm-12'>
                                    <p className='text-alert-center-bold'>Il tuo pacchetto è stato costruito con successo!</p>
                                    <p className='text-alert-center-bold'>Ecco cosa hai scelto:</p>
                                    
                                    {pacchetti.map((pacchetto, i) =>{
                                        return(
                                            <div className='row pachetti'>
                                                <div className='col-sm-2 none'></div>
                                                <div className='col-sm-6 text-confirm-ordine name dimcol6'>{pacchetto.nome_abbonamento}</div>
                                                <div className='col-sm-2 text-confirm-ordine val dimcol2'>€ {Number(pacchetto.costo).toLocaleString('it-IT', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                })}
                                                </div>
                                                <div className='col-sm-2'></div>
                                            </div>
                                        )
                                    })}
                                    <div className='row pack'>
                                        <div className='col-sm-2 none'></div>
                                        <div className='col-sm-6 text-confirm-ordine iva dimcol6'>Iva:</div>
                                        <div className='col-sm-2 text-confirm-ordine val cost fontwb dimcol2'>€ {Number(body["iva"]).toLocaleString('it-IT', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                })}</div>
                                        <div className='col-sm-2 none'></div>
                                    </div> 

                                    <div className='row pack'>
                                        <div className='col-sm-2 none'></div>
                                        <div className='col-sm-6 text-confirm-ordine color-blue dimcol6'>Totale Iva Inclusa:</div>
                                        <div className='col-sm-2 text-confirm-ordine color-blue cost dimcol2'>€ {Number(body["costoTotale"]).toLocaleString('it-IT', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                })}</div>
                                        <div className='col-sm-2 none'></div>
                                    </div>
                                    <div className='row'>
                                        <div className='termini-shop'>
                                        <input 
    type="checkbox" 
    name="acceptTerms" 
    id="acceptTerms" 
    className="form-check-input" 
    onChange={(e)=> handleCheckboxChange(e)}
  />
  <label className="form-check-label label-termini-shop">
    Per proseguire accetti le condizioni del servizio?{" "}  
    <a 
      href="/termini-condizioni" 
      target="_blank" 
      rel="noopener noreferrer" 
      style={{
        fontWeight: 'bold',
        color: '#0e1449',
        textDecoration: 'underline',
      }}
    >
    Leggi qui
    </a>
  </label>
                                        </div>
                                   
                                    </div>

                                </div>
                            </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                            {buttonNegative}
                        </Button>
                        <Button onClick={() => onPositive()} variant="success" disabled={disableConfirm}>
                            {buttonPositive}
                        </Button>
                    </Modal.Footer>
                </Modal>
            );    
    }else{
        return (
            <Modal size="lg" show={show} onHide={() => onNegative()} dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                            <div className='col-sm-12'>
                                <p className='icon-alert color-gray-pg'><i className="fa fa-exclamation-circle"></i></p>
                            </div>
                            <div className='col-sm-12'>
                                <p className='text-alert-center-bold'>Abbiamo rilevato un errore nella compilazione del tuo ordine! Riprova</p>
                            </div>
                        </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                        {buttonNegative}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

};

export default ConfirmationOrdine;

