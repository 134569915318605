import React, { FC, ReactNode } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { List } from 'reselect/es/types';
import { brotliDecompress } from 'zlib';

type Props = {
    show: boolean;
    title: ReactNode;
    body: ReactNode;
    buttonPositive: ReactNode;
    buttonNegative: ReactNode;
    disableButton?: boolean;
    //onPositive: () => void;
    onNegative: () => void;
};

const DetailAnagraficaImpreseModal: FC<Props> = ({
    show,
    title,
    body,
    buttonPositive,
    buttonNegative,
    //onPositive,
    onNegative,
    disableButton,
}) => {
    //console.log(body)
    if(body != null){

        let codiciAteco = "";
        let ateco_attivita = "Non Disponibile"
        if(body["info-attivita"]){
            if(body["info-attivita"]["classificazioni-ateco"] === null || body["info-attivita"]["classificazioni-ateco"] === undefined){
                codiciAteco = body["info-attivita"]["attivita"];
            }else if(Array.isArray(body["info-attivita"]["classificazioni-ateco"]["classificazione-ateco"])){
                body["info-attivita"]["classificazioni-ateco"]["classificazione-ateco"].map(item =>{
                    //codiciAteco = codiciAteco + item.
                    codiciAteco = codiciAteco + item["attributi"]["c-importanza"] + "-" + item["attributi"]["c-attivita"] + " "
                })
            }else{
                codiciAteco = body["info-attivita"]["classificazioni-ateco"]["classificazione-ateco"]["attributi"]["c-importanza"] + "-" + body["info-attivita"]["classificazioni-ateco"]["classificazione-ateco"]["attributi"]["c-attivita"]
            }
            
            ateco_attivita = body["info-attivita"]["attivita"];
        }

        if(codiciAteco === "") codiciAteco = "Non Disponibile"

        let dati_bilancio_data = "Non Disponibile"
        let dati_bilancio_ricavo = "Non Disponibile"
        let dati_bilancio_utile_perdite = "Non Disponibile"
        let dati_bilancio_valore_produzione = "Non Disponibile"

        if(body["dati-bilancio"]){
            dati_bilancio_data = body["dati-bilancio"]["attributi"]["dt-bilancio"];
            dati_bilancio_ricavo = body["dati-bilancio"]["attributi"]["ricavi"];
            dati_bilancio_utile_perdite = body["dati-bilancio"]["attributi"]["utile-perdite"];
            dati_bilancio_valore_produzione = body["dati-bilancio"]["attributi"]["valore-produzione"];
        }

        
        let capitale_sociale = "Non Disponibile";
        let capitale_sociale_valuta = "Non Disponibile";

        if(body["capitale"]){
            capitale_sociale = body["capitale"]["attributi"]["importo"];
            capitale_sociale_valuta = body["capitale"]["attributi"]["valuta"];
        }

        let addetti_anno = "Non Disponibile";
        let addetti_dip = "Non Disponibile";
        let addetti_indip = "Non Disponibile";

        if(body["addetti-impresa"]){
            addetti_anno = body["addetti-impresa"]["attributi"]["anno"];
            addetti_dip = body["addetti-impresa"]["attributi"]["n-dipendenti"];
            addetti_indip = body["addetti-impresa"]["attributi"]["n-indipendenti"];
        }

        let email = "Non Disponibile";
        if(body["email"]){
            email = body["email"];
        }

        let telefono = "Non Disponibile";
        if(body["telefono"]){
            telefono = body["telefono"];
        }



        return (
            <Modal size="xl" show={show} onHide={() => onNegative()} dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-sm-12 textcenterModalElenco boldtesto'><strong>Dati Impresa</strong></div>
                        <div className='col-sm-12'>Denominanzione: <strong>{body["denominazione"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Partita Iva: <strong>{body["attributi"]["partita-iva"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Codice Fiscale: <strong>{body["attributi"]["c-fiscale"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Cciaa - NRea: <strong>{body["attributi"]["cciaa"]} - {body["attributi"]["n-rea"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Stato Attività: <strong>{body["stato-impresa-attivita"]}</strong></div>
                        <div className='col-sm-12 heigthRigaElenco'>Forma Giuridica: <strong>{body["forma-giuridica"]}</strong></div>
                        <div className='col-sm-12 textcenterModalElenco boldtesto'>Data Iscrizione,Cancellazione, Ultimo Atto</div>

                        <div className='col-sm-6 heigthRigaElenco'>Data Ultimo Protocollo: <strong>{body["dt-mod-ultimo-protocollo-evaso"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Data Iscrizione: <strong>{body["dt-iscrizione"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Data Cancellazione: <strong>{body["dt-cancellazione"]}</strong></div>
                        <div className='col-sm-12 textcenterModalElenco boldtesto'>Dati Ubicazione</div>
                        <div className='col-sm-6 heigthRigaElenco'>Tipo Sede: <strong>{body["attributi"]["tipo-sede"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Comune: <strong>{body["indirizzo"]["attributi"]["comune"]}({body["indirizzo"]["attributi"]["sgl-provincia"]})</strong></div>
                        <div className='col-sm-12 heigthRigaElenco'>Indirizzo: <strong>{body["indirizzo"]["attributi"]["toponimo"]} {body["indirizzo"]["attributi"]["via"]} {body["indirizzo"]["attributi"]["n-civico"]} - {body["indirizzo"]["attributi"]["cap"]} {body["indirizzo"]["attributi"]["comune"]}({body["indirizzo"]["attributi"]["sgl-provincia"]})
                        </strong></div>
                        <div className='col-sm-12 textcenterModalElenco boldtesto'>Dati Contatto</div>
                        <div className='col-sm-6 heigthRigaElenco'>Email: <strong>{email}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Telefono: <strong>{telefono}</strong></div>
                        <div className='col-sm-12 textcenterModalElenco boldtesto'>Dati Capitale Sociale</div>
                        <div className='col-sm-6 heigthRigaElenco'>Capitale Sociale: <strong>{capitale_sociale}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Valuta: <strong>{capitale_sociale_valuta}</strong></div>

                        <div className='col-sm-12 textcenterModalElenco boldtesto'>Dati Bilancio</div>
                        <div className='col-sm-6 heigthRigaElenco'>Data Ultimo Bilancio: <strong>{dati_bilancio_data}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Valore Produzione: <strong>{dati_bilancio_valore_produzione}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Ricavi: <strong>{dati_bilancio_ricavo}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Utile/Perdite: <strong>{dati_bilancio_utile_perdite}</strong></div>

                        <div className='col-sm-12 textcenterModalElenco boldtesto'>Dati Addetti</div>
                        <div className='col-sm-6 heigthRigaElenco'>N. Addetti Dipendenti: <strong>{addetti_dip}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>N. Addetti Indipendenti: <strong>{addetti_indip}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Anno: <strong>{addetti_anno}</strong></div>

                        <div className='col-sm-12 textcenterModalElenco boldtesto'>Dati Attività</div>
                        <div className='col-sm-6 heigthRigaElenco'>Sezione: <strong>{body["sezioni"]["sezione"]}</strong></div>
                        
                        <div className='col-sm-6 heigthRigaElenco'>Codice Ateco: <strong>{codiciAteco}</strong></div>
                        <div className='col-sm-12 heigthRigaElenco'>Area Ateco: <strong>{ateco_attivita}</strong></div>

                        {/* <div className='col-sm-6 heigthRigaElenco'>Progressivo Localizzazione: <strong>{body["progressivo_localizzazione"]}</strong></div>   dt-mod-ultimo-protocollo-evaso
                        <div className='col-sm-6 heigthRigaElenco'>Numero Albo Artigiani: <strong>{body["n_albo_artigiani"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Codice Sezione Registro: <strong>{body["cod_sezione_registro"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Sezione Registro: <strong>{body["sezione_registro"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Codice Forma Giuridica: <strong>{body["cod_forma_giuridica"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Forma Giuridica: <strong>{body["forma_giuridica"]}</strong></div> */}
                        {/* <div className='col-sm-12 textcenterModalElenco boldtesto'>Dati Contatto</div> */}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                        {buttonNegative}
                    </Button>
                    {/* <Button onClick={() => onPositive()} variant="primary" disabled={disableButton}>
                        {buttonPositive}
                    </Button> */}
                </Modal.Footer>
            </Modal>
        );
    }else{
        return(
            <Modal size="lg" show={show}  dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Richiesta scaduta, riprova la ricerca e richiedere dettaglio</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                        {buttonNegative}
                    </Button>
                </Modal.Footer>
            </Modal>
        );

    }
};

export default DetailAnagraficaImpreseModal;

