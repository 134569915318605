import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetWs_AddebitiToInit, setWs_AddebitiMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteWs_Addebiti } from "services/ws_addebitiService";
import moment from "moment";
import ReactPaginate from "react-paginate";
import LoadingSpinner from "components/LoadingSpinner";
type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey) => void;
    getDataProdotti : (page, pageSize, searchKey) => void;
};
export const Ws_AddebitiTable: React.FC<Props> = ({ hideShowForm, handleRowEdit, getData, getDataProdotti }) => {
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);
    const rData = useSelector((state: RootState) => state.ws_addebiti);
    const rDataProdotto = useSelector((state: RootState) => state.ws_prodotti);
    const [isLoading, setIsLoading] = useState(false);

    const [messaggioNoData, setMessaggioNoData] = useState(false);



    const elencoImprese: any [] = []
    const [lstElencoImprese, setListElencoImprese] = useState(elencoImprese);
    const [postsPerPage] = useState(30);
    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [countImprese, setcountImprese] = useState(elencoImprese.length);

    const handleSearch = (search) => {
        setSearch(search); 
        //if (search.length > 0) {
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
        //}
    }


    const handleNomeProdotto = (row) => {
        let nome_prodotto = "in caricamento";
        if(rDataProdotto != null && rDataProdotto.list != null){

            rDataProdotto.list.forEach((prodotto) => {
                if(prodotto.id === row.id_prodotto){
                    nome_prodotto = prodotto.nome_prodotto;
                }
            })
        }
        return nome_prodotto;
    }
    


    const handlePerRowsChange = async (newPerPage, page) => {
        await getData(page, newPerPage, '');
    }
    const handlePageChange = (page) => {
        getData(page, rData.pageSize, '');
    };
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    }
    // useEffect(() => {
    //     if (rData && rData.list && rData.list.length === 0) {
    //         dispatch(resetWs_AddebitiToInit());
    //         getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
    //     }
    // })

    useEffect(() => {
        if (rData && rData.list && rData.list.length === 0) {
             dispatch(resetWs_AddebitiToInit());
             getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
             getAllPosts(rData.list);
        }else{
            getAllPosts(rData.list);
            setListElencoImprese(rData.list)
        }
    },[rData, rData.list.length, offset, rDataProdotto])


    const handleReset = () => {
        setSearch('');
        dispatch(resetWs_AddebitiToInit());
        getData(Constant.defaultPageNumber, rData.pageSize, '');
    }
    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deleteWs_Addebiti(rowData.id);
            if (response) {
                dispatch(resetWs_AddebitiToInit());
                dispatch(setWs_AddebitiMessage("Deleted Successfully"));
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                setShowDelete(false);
            } else {
                dispatch(setWs_AddebitiMessage("Some error occured!"));
            }
        }
    }

    const handleRowSelection = (row) => {
        //console.log(row); // Row Selection Functionality can be written here
    }
    const handleAddButtonClick = () => {
        dispatch(setWs_AddebitiMessage(''));
        hideShowForm('add');
    }

    const columns = [
        {name: 'ID Richiesta', selector: row => "RIC_" + row.id, sortable: true},
        // {name: 'id_utente', selector: row => row.id_utente, sortable: true},
        // {name: 'id_prodotto', selector: row => row.id_prodotto, sortable: true},
        {name: 'Servizio', selector: row => handleNomeProdotto(row), sortable: true},
        // {name: 'costo', selector: row => row.costo, sortable: true},
        {name: 'Data Richiesta', selector: row => moment(row.data_richiesta).format('DD/MM/YYYY HH:mm:ss'), sortable: true},

        // ,{
        //     name: '',
        //     button: true,
        //     cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowEdit(row)}>Edit</Button>,
        // },
        // {
        //     name: '',
        //     button: true,
        //     cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowDeleteClick(row)}>Delete</Button>,
        // },
    ];


    const getAllPosts = async (elenco_input) => {
        let res = rData.list//rDataElenco
        // if(elenco_input != null && elenco_input.length > 0){
        //     res = elenco_input
        // }
        if(elenco_input != null){
            res = elenco_input
        }
        const data = res
        let slice = null;
        if(res.length < 11){
             //res.list;
            slice = data
        }else{

            if(offset > 1){
                slice = data.slice((offset - 1) * 10 , (((offset - 1) * 10) + postsPerPage) -1)
            }else{
                slice = data.slice(offset - 1 , offset - 1 + postsPerPage)
            }
            
        }
        // For displaying Data
        const postData = getPostData(slice)
      
        // Using Hooks to set value
        setAllPosts(postData)
        setPageCount(Math.ceil(data.length / postsPerPage))

        setcountImprese(res.length)
    }

    const getPostData = (data) => {
        return (
          data.map(row => 
            <Row className="shadow box-ricerca-elenco row  margin-row">            
            <Col md={10} xs={12} xl={10}>
                <Row>
                    <div className="col-sm-2">Id Servzio: <p><strong>RC_{valoreDato(row.id)}</strong></p></div>
                    <div className="col-sm-6">Nome Servizio: <p><strong>{handleNomeProdotto(row)}</strong></p></div>
                    <div className="col-sm-4">Data: <p><strong>{moment(row.data_richiesta).format('DD/MM/YYYY HH:mm:ss')}</strong></p></div>
                </Row>
            </Col>
            
            </Row>
            )
        )
      
      }

      const valoreDato = (dato) => {
        if(dato == null || dato === ""){
            return "Dato non fornito"
        }else{
            return dato
        }
    }

      const filterBySearch = (event) => {
        const query = event
        let query_stato = query
        var updatedList = lstElencoImprese
        updatedList = updatedList.filter((item) => {
            if(
            (item.id != null && ("RC_"+ String(item.id)).toLowerCase().indexOf(query.toLowerCase()) !== -1) || 
            (item.id_prodotto != null && handleNomeProdotto(item).toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
            (item.anagrafica != null && item.anagrafica.toLowerCase().indexOf(query.toLowerCase()) !== -1)
            ){
                return item
            }
        });
        // Trigger render with updated values
        setListElencoImprese(updatedList.length > 0 ? updatedList : [])
        getAllPosts(updatedList.length > 0 ? updatedList : [])

        if(updatedList.length > 0){
            setMessaggioNoData(false)
        }else{
            setMessaggioNoData(true)
        }


    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    const handleSearchButton = () => {

        filterBySearch(search)
    
    }









    return (
        <>
        <div className="d-flex flex-column" >
            <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
                    <div className="col-sm-9">
                        <h4>Lista Addebiti</h4>
                    </div>
                    <div className="col-sm-3 text-align-right">
                        
                        <>
                        <Button variant="info" className="" onClick={() => handleReset()}><i className="fa fa-refresh"></i> Aggiorna ({countImprese})</Button>
                        
                        </>
                                        
                    </div>
            </Row>
        </div>
        <div>
            <Col className="ml-auto padding-bottom20" md={4} xs={12} xl={4}>
                    <InputGroup>
                        <Form.Control
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-search"
                            //onChange={(e) => setSearch(e.target.value)}
                            onChange={(e) => {
                                const value = e.target.value;
                                setSearch(value);
                                if (value.length > 2) {
                                    handleSearchButton(); // Esegui la ricerca direttamente
                                } else {
                                    handleSearch('') // Resetta la lista quando la ricerca è troppo corta
                                }
                            }}
                        />
                        <Button disabled={search.length <= 2} variant="dark" className="rounded-0 rounded-right" id="button-search" onClick={handleSearchButton}>
                            Search
                        </Button>
                    </InputGroup>
                </Col>
        </div>
        <div className="d-flex flex-column" >
                
        {isLoading ? <LoadingSpinner /> : isLoading}
        <div className="main-app">
            <ReactPaginate
                previousLabel={"pre"}
                nextLabel={"succ"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                //subContainerClassName={"pages pagination"}
                activeClassName={"active"} />
                {/* Display all the posts */}
                {posts}
                {/* Using React Paginate */}
        </div>
        <div className="row shadow box-ricerca-elenco shadow-margin-zero">
                             <div className="col-sm-12 box-message-dim box-message-padding-noimp">
                                 <p className="box-message-general">Nessun dato con questa ricerca</p>
                             </div>
                         </div>

     </div>
        </>
        
        // <Card className="shadow mb-4">
        //     <Card.Header className="py-3">
        //         <h6 className="m-0 font-weight-bold text-primary">Aggiorna Lista ({rData.totalCount})
        //             <Button variant="light" className="btn-circle btn-sm ml-2" onClick={handleReset}><i className="fa fa-refresh"></i></Button>
        //             {/* <Button className="btn-icon-split float-right" onClick={handleAddButtonClick}>
        //                 <span className="icon text-white-50">
        //                     <i className="fas fa-add"></i>
        //                 </span>
        //                 <span className="text">Add New</span>
        //             </Button> */}
        //         </h6>
        //     </Card.Header>
        //     <Card.Body>
        //         <Col className="ml-auto" md={3} xs={12} xl={3}>
        //             <InputGroup>
        //                 <Form.Control
        //                     placeholder="Search"
        //                     aria-label="Search"
        //                     aria-describedby="basic-search"
        //                     onChange={(e) => {handleSearch(e.target.value)}}
        //                 />
        //                 <Button disabled={search.length <= 2} variant="dark" className="rounded-0 rounded-right" id="button-search" onClick={handleSearch}>
        //                     Search
        //                 </Button>
        //             </InputGroup>
        //         </Col>
        //         <div className="table-responsive">
        //             <DataTable
        //                 selectableRows={false}
        //                 onSelectedRowsChange={handleRowSelection}
        //                 paginationPerPage={Constant.defaultPageNumber}
        //                 paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
        //                 columns={columns} data={rData.list}
        //                 onChangeRowsPerPage={handlePerRowsChange}
        //                 paginationTotalRows={rData.totalCount}
        //                 className="table table-bordered"
        //                 pagination
        //                 paginationServer
        //                 onChangePage={handlePageChange}></DataTable>
        //         </div>
        //     </Card.Body>
        //     <ConfirmationModal buttonNegative="Cancel" buttonPositive="Delete" title="Delete Confirmation" show={showDelete} body={"Are you sure?"} onNegative={() => setShowDelete(false)} onPositive={handleServerDelete} />
        // </Card>
    );
}

