import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Card, InputGroup, Button, Row, DropdownButton, Dropdown, Tabs, Tab, TabPane, NavLink } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { activateMenu, setAreaGeograficaList, setAreaGeograficaMessage, setCodici_AtecoList, setCodici_AtecoMessage, setElenco_Imprese_EstesoMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getTrovaImpresa, getTrovaImpresaPec } from "services/ricerca_impresa_service";
import Layout from "template";
import ConfirmationModal from "template/ConfirmationModal";
import DetailModal from "template/DetailModal";
import LoadingSpinner from "../LoadingSpinner";
import Form from 'react-bootstrap/Form';
import { isTemplateExpression } from "typescript";
import ConfirmationModalNoAcquisto from "template/ConfirmationModalNoAcquisto";
import ModalErrorGenerico from "template/ModalErrorGenerico";
import { sassFalse } from "sass";
import DetailImpresaPuntualeModal from "template/DetailImpresaPuntualeModal";
import ConfirmationVisura from "template/ConfirmationVisura";
import Select from 'react-select';
import ConfirmationDatiObbligatoriElenco from "template/ConfirmationDatiObbligatoriElenco";
import { getRicercaElencoImpresaPreventivo, getRichiestaElencoImpresa } from "services/richiesta_elenco_impresa_esteso";
import ConfirmationPreventivoElenco from "template/ConfirmationPreventivoElenco";
import ConfirmationDifferita from "template/ConfirmationDifferita";
import { getCodici_Ateco } from "services/codici_atecoService";
import { getComuni_Catastali } from "services/comuni_catastaliService";
import { getWs_Provincieinfocamere } from "services/ws_provincieinfocamereService";

import { useLocation } from 'react-router-dom';
import { controlloDisponibilita, getDatiAreaGeografica, getDatiNaturaGiuridica, getDatiateco, getDettaglioAzienda, getRicercaEstesa } from "services/ricercaUnicaService";
import { ReactTags, TagSuggestion  } from "react-tag-autocomplete";
import ConfirmationBilancio from "template/ConfirmationBilancio";
import { getUltimoBilancio } from "services/richiesta_bilancio";
import ModalConfirmGenerico from "template/ModalConfirmGenerico";
import { Doughnut } from "react-chartjs-2";
import DonutChart from "components/grafici/DonutChart";
import DonutChartSemi from "components/grafici/DonutChartSemi";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import L, { LatLngTuple } from 'leaflet'
import 'leaflet/dist/leaflet.css';
import placeholderImage from '../../asset/placeholder_azienda.png';
import LineChart from "components/LineChart";
import { array, number, string } from "yup";
import { ListaRicerche } from ".";
import { acquistaListaRicerca, eliminaLista, getListaAcquistate, getListaByNomeFile, getListaSalvate } from "services/lista_ricerche_service";
import ConfirmationSalvaRicerca from "template/ConfirmationSalvaRicerca";
import DetailListaRicercaModal from "template/DetailListaRicercaModal";
import { setError } from "redux/slices/auth";
import ConfirmationAcquistaRicerca from "template/ConfirmationAcquistaRicerca";
import ConfirmationControlloDisponibilita from "template/ConfirmationControlloDisponibilita";



type Props = {

    numero_imprese: Number
    getData: (page, pageSize, searchKey, datiricerca) => void

};
export const ListaRicercheAzienda: React.FC<Props> = ({getData, numero_imprese}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { id } = useParams();

    const [listaSalvate, setListaSalvate] = useState(undefined);
    const [listaAcquistate, setListaAcquistate] = useState(undefined);
    const [rowDataVisura, setRowDataVisura] = useState(undefined);
    const [showRichiestaVisura, setRichiestaVisura] = useState(false);
    const [showSalvaRicerca, setShowSalvaRicerca] = useState(false);
    const [showDatiRicerca, setShowDatiRicerca] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);
    const [nomeLista, setNomeLista] = useState(undefined);
    const [idPianoEstrazione, setIdPianoEstrazione] = useState(undefined);
    const [idRicerca, setIdRicerca] = useState(undefined);
    const [numeroAnagrafiche, setNumeroAnagrafiche] = useState(undefined);
    const [showAcquistaRicerca, setShowAcquistaRicerca] = useState(false);
    const [datiAcquisto, setDatiAcquisto] = useState(undefined);
    

    const [isLoading, setIsLoading] = useState(false);


    const { state } = useLocation();








    useEffect(() => {
        
        if(listaSalvate == null)getListaRicercheSalvate()
        
        
      }, [listaSalvate, listaAcquistate]);

    
    const getListaRicercheSalvate = () =>{
        setIsLoading(true)
        let dati_ricerca = {
            "acquistate": false
        }
        getListaSalvate(dati_ricerca).then((response) =>{
            if (response && response.data.response != null && response.data.status === 200) {
                let salvata = []
                let acquistata = []
                if(response.data.lista_ricerca != null){
                    response.data.lista_ricerca.map((lista, i) =>{
                        if(lista.acquistata) acquistata.push(lista)
                        if(!lista.acquistata) salvata.push(lista)
                    })
                    setListaAcquistate(acquistata)
                    setListaSalvate(salvata)
                    setIsLoading(false)
                }else{
                    setIsLoading(false)
                }
            }else{
                setIsLoading(false)
            }
        })

    }

    const getListaRicercheAcquistate = () =>{

        let dati_ricerca = {
            "acquistate": true
        }
        getListaAcquistate(dati_ricerca).then((response) =>{
            if (response && response.data.response != null && response.data.status === 200) {
                //console.log(response)
                setListaAcquistate(response.data.response.liste)
            }


        })
    }

    const handleSalvaRicerca = (id_lista, nome_lista, numero_anagrafiche) =>{
        setIdRicerca(id_lista)
        setNumeroAnagrafiche(numero_anagrafiche)
        setShowAcquistaRicerca(true)
        let dati_acquisto ={
            "anagrafiche": numeroAnagrafiche,
            "nome_lista": nome_lista
        }
        setDatiAcquisto(dati_acquisto)
    }

    const handleConfermaEliminaRicerca = (id_lista) => {
        let dati_lista = {
            "id_lista": id_lista
        }
        eliminaLista(dati_lista).then((response) =>{
            if (response && response.data.response != null && response.data.status === 200) {
                setShowDatiRicerca(false)
                getListaRicercheSalvate()
                setShowDelete(false)
            }else{
                setRowData(response.data.response)
                setShowDelete(false)
            }
        })
    }

    const handleEliminaRicerca = (id_lista) =>{
        setIdRicerca(id_lista)
        setShowDelete(true)
        
    }

    const handleDettaglioRicerca = (id_lista) => {
        //console.log(id_lista)
        setIsLoading(true)

        let nome_lista = {
            "nome_lista": id_lista
        }

        getListaByNomeFile(nome_lista).then((response) =>{
            if (response && response.data.response != null && response.data.status === 200) {
                //console.log(response.data.response)
                setRowData(response.data.response)
                setIdPianoEstrazione(response.data.id)
                setNomeLista(response.data.nome_lista)
                setShowDatiRicerca(true)
                setIsLoading(false)
                
            }else{
                setRowData(response.data.response)
                setShowDatiRicerca(true)
                setIsLoading(false)
            }
        })
    }

    const valoreDato = (dato) => {
        if(dato == null){
            return "Dato non fornito"
        }else{
            return dato
        }
    }

    const convertiData = (data) => {
        let result = "-"
        if(data != null){
            let theDate=new Date(data);
            let option={
                day:"2-digit",
                month:"2-digit",
                year:"numeric",
            }
            //let result=theDate.toLocaleDateString('it-IT').replaceAll("/","-")
            const timeFormat: Intl.DateTimeFormatOptions = {year: 'numeric', month: '2-digit', day: '2-digit', second:'2-digit', hour: '2-digit', minute: '2-digit', hour12: false }
            result=theDate.toLocaleDateString('it', timeFormat)
        }
        
        return result
    }

    const handleAcquisto = (id_lista, numero_anagrafiche) => {

        //body.url_ricerca = body.url_ricerca + "&nome_salvataggio_lista="+nomeLista
    
        //console.log(id_lista)
    
       
        let preventivo = {
            "preventiva": false
        }

        let dati_acquisto ={
            "anagrafiche": numeroAnagrafiche,
            "nome_lista": nomeListaRicercaAcquisto
        }

        let ricerca = {
            "nome_lista": id_lista,
            "anagrafiche": numeroAnagrafiche,
            "preventivo": preventivo,
            "dati_acquisto": dati_acquisto
        }



        acquistaListaRicerca(ricerca).then((response)=> {
            if(response.data.status === 500){
                setShowDisponibilitaAcquisto(true)
                setDatiAcquisto(undefined)
                //setErrore(true)
            }else if(response.data.status === 200){
                setShowDisponibilitaAcquisto(false)
                setDatiAcquisto(dati_acquisto)
                setShowAcquistaRicerca(true)
                //console.log(response.data)        
        }
        })


    }
    const [responseDisponibilita, setResponseDisponibilita] = useState(undefined);
    const [showDisponibilita, setShowDisponibilita] = useState(false);
    const [idPiano, setIdPiano] = useState(undefined);
    const [nomeListaRicerca, setNomeListaRicerca] = useState(undefined);
    const handleRichiestaElenco = (id_piano_estrazione, nome_lista) =>{

        let dati_ricerca = {
            "report": 9 
        }

        controlloDisponibilita(dati_ricerca).then((response) =>{
            //console.log(response)
            if(response != null && response.data.status === 200){
                let body = {
                    "anagrafiche": response.data.anagrafiche,
                    "response": response.data.response,
                    "prodotto": response.data.prodotto

                }
                setShowDatiRicerca(false)
                setIdPiano(id_piano_estrazione)
                setNomeListaRicerca(nome_lista)
                setResponseDisponibilita(body)
                setShowDisponibilita(true)
                setIsLoading(false)

            }else{
                let body = null
                setShowDatiRicerca(false)
                setResponseDisponibilita(body)
                setShowDisponibilita(true)
            }

        })
        


    }

    const handleConfermaRichiestaElenco = () =>{

        navigate("/ricerca_unica/"+idPiano+"PE"+nomeListaRicerca, {replace: true})

    }

    const [responseDisponibilitaAcquisto, setResponseDisponibilitaAcquisto] = useState(undefined);
    const [showDisponibilitaAcquisto, setShowDisponibilitaAcquisto] = useState(false);
    const [idPianoAcquisto, setIdPianoAcquisto] = useState(undefined);
    const [nomeListaRicercaAcquisto, setNomeListaRicercaAcqusito] = useState(undefined);
    const handleRichiestaElencoAcqusito = (id_piano_estrazione, nome_lista, numero_anagrafiche) =>{

        let dati_ricerca = {
            "report": 14,
            "numero_anagrafiche": numero_anagrafiche 
        }

        controlloDisponibilita(dati_ricerca).then((response) =>{
            //console.log(response)
            if(response != null && response.data.status === 200){
                let body = {
                    "anagrafiche": response.data.anagrafiche,
                    "response": response.data.response,
                    "prodotto": response.data.prodotto

                }
                //setShowDatiRicerca(false)
                setIdPianoAcquisto(id_piano_estrazione)
                setNomeListaRicercaAcqusito(nome_lista)
                setResponseDisponibilitaAcquisto(body)
                setShowDisponibilitaAcquisto(true)
                setNumeroAnagrafiche(numero_anagrafiche)
                setIsLoading(false)

            }else{
                let body = null
                setShowDatiRicerca(false)
                setResponseDisponibilita(body)
                setShowDisponibilita(true)
            }

        })
        


    }

    

    const handleConfermaRichiestaElencoAcquisto = (id_lista, numero_anagrafiche) =>{

        handleAcquisto(idPianoAcquisto, numero_anagrafiche)

    }

    
    return (
        <>

        <Col md={12} xl={12} xs={12} className=" mb-4 shadow">
        {isLoading ? <LoadingSpinner /> : isLoading}
        <>
            <Row className="dettaglio">
                <Card.Body>
                    <Tabs
                            defaultActiveKey="salvate"
                            //activeKey={showTipoRicerca}
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            fill
                        >
                        <Tab eventKey="salvate" className="dettaglio" title={<span><i className="fa fa-list decoration-none"></i> Salvate</span>}>
                            <Row className=" row heigth-max ">
                                {listaSalvate != null && listaSalvate.map((lista, i) =>{

                                    // let acquistata = false
                                    // listaAcquistate.map((listaAc, i) =>{
                                    //     if(listaAc.nome_lista === lista.nome_lista) acquistata = true
                                    // })
                                    return(
                                    <Col md={4} xl={4} xs={12} className="padding-bottom20">
                                        <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
                                            <div className="col-sm-12"><h5 onClick={() => handleDettaglioRicerca(lista.id_lista)} className="posizioni-lista cursor-pointer">{lista.nome_lista}</h5></div>
                                            {/* <div className="col-sm-6">Lista: <p><strong>{valoreDato(lista.tipo_lista)}</strong></p></div> */}
                                            <div className="col-sm-6 padding-t-20 box-ris-etichetta margin-b-10">Anagrafiche: <p className="margin-t-5"><strong className="box-ris-dato margin-t-5">{valoreDato(lista.numero_anagrafiche)}</strong></p></div>
                                            <div className="col-sm-6 padding-t-20 box-ris-etichetta margin-b-10">Creata: <p className="margin-t-5"><strong className="box-ris-dato margin-t-5">{convertiData(lista.created)}</strong></p></div>
                                            <div className="col-sm-6"></div>
                                            <div className="col-md-12 align-left">
                                                <div className="row p-ricerche">
                                                {listaSalvate === null || (listaSalvate != null && listaSalvate.length === 0) ? (
                                                    <>
                                                    <div className="col-sm-6 button-disable text-align-left p-ricerche">
                                                    {/* <span className="font-size-large">
                                                    <i className="fa-solid fa-shopping-cart margin-r-5"></i> Acquista</span> */}
                                                    <Button variant="success" className="" disabled={listaSalvate === null || (listaSalvate != null && listaSalvate.length === 0)} onClick={() => handleRichiestaElencoAcqusito(lista.id_lista, lista.nome_lista, lista.numero_anagrafiche)}><i className="fa-solid fa-shopping-cart"></i> Acquista</Button>
                                                

                                                    </div>
                                                    <div className="col-sm-6 button-disable text-align-right-list p-ricerche">
                                                    <Button variant="danger" className="" disabled={listaSalvate === null || (listaSalvate != null && listaSalvate.length === 0)} onClick={() => handleEliminaRicerca(lista.id_lista)}><i className="fa-solid fa-trash"></i> Elimina</Button>

                                                    {/* <span 
                                                className="font-size-large" 
                                                
                                                ><i className="fa-solid fa-trash margin-r-5"></i> Elimina</span> */}

                                                    </div>
                                                   
                                                
                                                
                                                    </>
                                                
                                                
                                                ):(
                                                    <>
                                                     <div className="col-sm-6 button-vis-bil text-align-left p-ricerche">
                                                     <Button variant="success" className="" disabled={listaSalvate === null || (listaSalvate != null && listaSalvate.length === 0)} onClick={() => handleRichiestaElencoAcqusito(lista.id_lista, lista.nome_lista, lista.numero_anagrafiche)}><i className="fa-solid fa-shopping-cart"></i> Acquista</Button>
                                                
                                                     {/* <span className="margin-r-5 font-size-large" 
                                                onClick={() => handleRichiestaElencoAcqusito(lista.id_lista, lista.nome_lista, lista.numero_anagrafiche)}>
                                                    <i className="fa-solid fa-shopping-cart margin-r-5"></i> Acquista</span> */}
                                                     </div>
                                                     <div className="col-sm-6 button-vis-bil text-align-right-list p-ricerche">
                                                     <Button variant="danger" className="" disabled={listaSalvate === null || (listaSalvate != null && listaSalvate.length === 0)} onClick={() => handleEliminaRicerca(lista.id_lista)}><i className="fa-solid fa-trash"></i> Elimina</Button>

                                                     {/* <span 
                                                className="margin-r-5 font-size-large" 
                                                
                                                onClick={() => handleEliminaRicerca(lista.id_lista)}><i className="fa-solid fa-trash margin-r-5"></i> Elimina</span> */}
                                                     </div>
                                                    
                                                
                                                
                                                    </>
                                                    

                                                )

                                                }

                                                </div>
                                                
                                                
                                                
                                            </div>
                                            
                                        </Row>
                                    </Col>
                                    )
                                    
                                })}

                                {listaSalvate != null && listaSalvate.length == 0 &&
                                    <Col md={12} xl={12} xs={12} className="padding-bottom20">
                                        <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
                                            <h5>
                                                <strong>Non ci sono liste salvate</strong>
                                            </h5>
                                        </Row>
                                    </Col>
                                }
                                 
                            
                                
                            </Row>
                        </Tab>
                        <Tab eventKey="acquistate" onEnter={()=>getListaRicercheSalvate()} title={<span><i className="fa fa-shopping-cart decoration-none"></i> Acquistate</span>}>
                            <Row className="row heigth-max">
                            {listaAcquistate != null && listaAcquistate.map((lista, i) =>{
                                    return(
                                    <Col md={4} xl={4} xs={12} className="padding-bottom20">
                                        <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
                                            <div className="col-sm-12"><h5 onClick={() => handleDettaglioRicerca(lista.id_lista)} className="posizioni-lista cursor-pointer">{lista.nome_lista}</h5></div>
                                            {/* <div className="col-sm-6">Lista: <p><strong>{valoreDato(lista.tipo_lista)}</strong></p></div> */}
                                            <div className="col-sm-6 box-ris-etichetta">Anagrafiche: <p className="margin-t-5"><strong className="box-ris-dato">{valoreDato(lista.numero_anagrafiche)}</strong></p></div>
                                            <div className="col-sm-6 box-ris-etichetta ">Creata: <p className="margin-t-5"><strong className="box-ris-dato">{convertiData(lista.created)}</strong></p></div>
                                            <div className="col-sm-6 box-ris-etichetta padding-t-0">Evasa: <p className="margin-t-5"><strong className="box-ris-dato">{convertiData(lista.completed)}</strong></p></div>


                                            
                                            
                                            {lista.url_download === null ? (
                                                <div className="col-sm-12 button-disable text-align-left padding-t-15">
                                                    <span className="font-size-large">
                                                    <i className="fa fa-hourglass-end margin-r-5"></i> Elenco in eleborazione</span>
                                                </div>

                                            ):(
                                                // <div className="col-sm-12 button-vis-bil text-align-left padding-t-15">
                                                // {/* <span className=" font-size-large" 
                                                // onClick={() => handleSalvaRicerca(lista.id_lista, lista.nome_lista, lista.numero_anagrafiche)}>
                                                //     <i className="fa-solid fa-cloud-download margin-r-5"></i> Scarica Elenco</span> */}
                                                //     <Button variant="success" className="" disabled={lista.url_download === null } onClick={() => handleSalvaRicerca(lista.id_url_download)}><i className="fa-solid fa-cloud-download"></i> Scarica Elenco</Button>
                                                // </div>
                                                <div className="col-sm-12 button-vis-bil text-align-left padding-t-15">
                                                <Button 
                                                variant="success" 
                                                className="" 
                                                disabled={!lista.url_download} 
                                                onClick={() => window.open(lista.url_download, "_blank")}
                                                >
                                                <i className="fa-solid fa-cloud-download"></i> Scarica Elenco
                                                </Button>
                                                </div>

                                            )

                                            }
                                                {/* <span variant="success" className="" 
                                                onClick={() => handleSalvaRicerca(lista.id_lista, lista.nome_lista, lista.numero_anagrafiche)}>
                                                    <i className="fa-solid fa-cloud-download"></i> Scarica</span> */}
                                        
                                            
                                        </Row>
                                    </Col>
                                    )
                                    
                                })}

                                {listaAcquistate != null && listaAcquistate.length == 0 &&
                                    <Col md={12} xl={12} xs={12} className="padding-bottom20">
                                        <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
                                            <h5>
                                                <strong>Non ci sono liste acquistate</strong>
                                            </h5>
                                        </Row>
                                    </Col>
                                }
                                
                            </Row>

                        </Tab>
                    </Tabs>
                </Card.Body>
            </Row>
        </>            
        </Col>
        <ConfirmationSalvaRicerca buttonNegative="Annulla" buttonPositive="Continua" title="Richiesta documento" show={showSalvaRicerca} body={"Sei sicuro di voler richiedere il bilancio di questa società?"} onNegative={() => setShowSalvaRicerca(false)} />
        <DetailListaRicercaModal buttonNegative="Chiudi" buttonPositive="Richiedi Elenco" title="Dati Ricerca" show={showDatiRicerca} body={rowData} onNegative={() => setShowDatiRicerca(false)} onPositive={() => handleRichiestaElenco(idPianoEstrazione, nomeLista)} />
        <ConfirmationModal buttonNegative="Chiudi" buttonPositive="Conferma" title="Delete Confirmation" show={showDelete} body={"Sei sicuro di voler eliminare questa ricerca?"} onNegative={() => setShowDelete(false)} onPositive={() => handleConfermaEliminaRicerca(idRicerca)} />
        <ConfirmationAcquistaRicerca buttonNegative="Chiudi"  title="Acquista Lista Ricerca" show={showAcquistaRicerca} body={datiAcquisto} onNegative={() => setShowAcquistaRicerca(false)} />
        <ConfirmationControlloDisponibilita
            buttonNegative="Chiudi" 
            buttonPositive="Conferma" 
            title="Richiesta Servizio" 
            show={showDisponibilita} 
            body={responseDisponibilita} 
            onNegative={() => setShowDisponibilita(false)}
            onPositive={() => handleConfermaRichiestaElenco()}  
        />
        <ConfirmationControlloDisponibilita
            buttonNegative="Chiudi" 
            buttonPositive="Conferma" 
            title="Richiesta Servizio" 
            show={showDisponibilitaAcquisto} 
            body={responseDisponibilitaAcquisto} 
            onNegative={() => setShowDisponibilitaAcquisto(false)}
            onPositive={() => handleConfermaRichiestaElencoAcquisto(idRicerca, numeroAnagrafiche)}  
        />
        </>
    );
};

