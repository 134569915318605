import React, { FC, ReactNode } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { List } from 'reselect/es/types';
import { brotliDecompress } from 'zlib';

type Props = {
    show: boolean;
    title: ReactNode;
    body: ReactNode;
    buttonPositive: ReactNode;
    buttonNegative: ReactNode;
    disableButton?: boolean;
    //onPositive: () => void;
    onNegative: () => void;
};

const DetailElencoImpreseModal: FC<Props> = ({
    show,
    title,
    body,
    buttonPositive,
    buttonNegative,
    //onPositive,
    onNegative,
    disableButton,
}) => {
    //console.log(body)
    if(body != null){
        return (
            <Modal size="xl" show={show}  dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-sm-12 heigthRigaElenco'>Progressivo: <strong>{body["progressivo"]}</strong></div>
                        <div className='col-sm-12'>Denominanzione: <strong>{body["denominazione"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Codice Fiscale: <strong>{body["codice_fiscale"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Partita Iva: <strong>{body["partita_iva"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Cciaa: <strong>{body["provincia_nrea"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>NRea: <strong>{body["n_rea"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Tipo Sede: <strong>{body["tipo_sede"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Progressivo Localizzazione: <strong>{body["progressivo_localizzazione"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Numero Albo Artigiani: <strong>{body["n_albo_artigiani"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Codice Sezione Registro: <strong>{body["cod_sezione_registro"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Sezione Registro: <strong>{body["sezione_registro"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Codice Forma Giuridica: <strong>{body["cod_forma_giuridica"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Forma Giuridica: <strong>{body["forma_giuridica"]}</strong></div>
                        <div className='col-sm-12 textcenterModalElenco boldtesto'>Dati Contatto</div>
                        <div className='col-sm-6 heigthRigaElenco'>Telefono: <strong>{body["telefono"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Email: <strong>{body["email"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Sito Internet: <strong>{body["sito_internet"]}</strong></div>
                        <div className='col-sm-12 textcenterModalElenco boldtesto'>Data Iscrizione/Cancellazione/Protocollo</div>
                        <div className='col-sm-6 heigthRigaElenco'>Data Iscrizione: <strong>{body["data_iscrizione"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Data Cancellazione: <strong>{body["data_cancellazione"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Data Ultimo Protocollo: <strong>{body["data_ultimo_protocollo"]}</strong></div>
                        <div className='col-sm-12 textcenterModalElenco boldtesto'>Dati Ubicazione</div>
                        <div className='col-sm-6 heigthRigaElenco'>Sigla Provincia: <strong>{body["sigla_provincia"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Provincia Istat: <strong>{body["provincia_istat"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Sigla Provincia Camerale: <strong>{body["sigla_provincia_camerale"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Comune: <strong>{body["comune"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Codice Toponimo: <strong>{body["codice_toponimo"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Toponimo: <strong>{body["toponimo"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Indirizzo: <strong>{body["indirizzo"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Numero Civico: <strong>{body["numero_civico"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Codice Stradario: <strong>{body["codice_stradario"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Cap: <strong>{body["cap"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Codice Comune Catastale: <strong>{body["codice_comune_catastale"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Codice Comune Camerale: <strong>{body["codice_comune_camerale"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Frazione: <strong>{body["frazione"]}</strong></div>
                        <div className='col-sm-12'>Altre Indicazioni Indirizzo: <strong>{body["altre_indicazioni_indirizzo"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Codice Comune Estero: <strong>{body["codice_stato_estero"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Stato Estero: <strong>{body["stato_estero"]}</strong></div>
                        <div className='col-sm-12 textcenterModalElenco boldtesto'>Dati Addetti</div>
                        <div className='col-sm-6 heigthRigaElenco'>Anno Dichiarazione Addetti: <strong>{body["anno_dichiarazione_addetti"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Addetti Indipendenti: <strong>{body["addetti_indipendenti"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Addetti Dipendenti: <strong>{body["addetti_dipendenti"]}</strong></div>
                        <div className='col-sm-12 textcenterModalElenco boldtesto'>Dati Capitale Sociale</div>
                        <div className='col-sm-6 heigthRigaElenco'>Capitale Sociale: <strong>{body["capitale_sociale"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Valuta: <strong>{body["valuta"]}</strong></div>
                        <div className='col-sm-12 textcenterModalElenco boldtesto'>Dati Bilancio</div>
                        <div className='col-sm-6 heigthRigaElenco'>Data Ultimo Bilancio Xbrl: <strong>{body["data_ultimo_bilancio_xbrl"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Valore Produzione: <strong>{body["valore_produzione"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Ricavi: <strong>{body["ricavi"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Utile/Perdite: <strong>{body["utile_perdite"]}</strong></div>
                        <div className='col-sm-12 textcenterModalElenco boldtesto'>Dati Attività</div>
                        <div className='col-sm-6 heigthRigaElenco'>Codice Stato Attività: <strong>{body["codice_stato_attivita"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Stato Attività: <strong>{body["stato_attivita"]}</strong></div>
                        <div className='col-sm-12'>Codice Ateco Importanza: <strong>{body["codice_ateco_importanza"]}</strong></div>
                        <div className='col-sm-12'>Attività Dichiarata: <strong>{body["attivita_dichiarata"]}</strong></div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                        {buttonNegative}
                    </Button>
                    {/* <Button onClick={() => onPositive()} variant="primary" disabled={disableButton}>
                        {buttonPositive}
                    </Button> */}
                </Modal.Footer>
            </Modal>
        );
    }else{
        return(
            <Modal size="lg" show={show}  dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Richiesta scaduta, riprova la ricerca e richiedere dettaglio</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                        {buttonNegative}
                    </Button>
                </Modal.Footer>
            </Modal>
        );

    }
};

export default DetailElencoImpreseModal;

