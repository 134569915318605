import React, { useEffect, useState } from "react";
import { Col, Card, InputGroup, Button, Row, DropdownButton, Dropdown, Tabs, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { activateMenu, setCodici_AtecoList, setCodici_AtecoMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getTrovaImpresa, getTrovaImpresaPec } from "services/ricerca_impresa_service";
import Layout from "template";
import ConfirmationModal from "template/ConfirmationModal";
import DetailModal from "template/DetailModal";
import { DashboardCard } from "./DasboardCard";
import LoadingSpinner from "../LoadingSpinner";
import Form from 'react-bootstrap/Form';
import { isTemplateExpression } from "typescript";
import ConfirmationModalNoAcquisto from "template/ConfirmationModalNoAcquisto";
import ModalErrorGenerico from "template/ModalErrorGenerico";
import { sassFalse } from "sass";
import DetailImpresaPuntualeModal from "template/DetailImpresaPuntualeModal";
import ConfirmationVisura from "template/ConfirmationVisura";
import Select from 'react-select';
import ConfirmationDatiObbligatoriElenco from "template/ConfirmationDatiObbligatoriElenco";
import { getRicercaElencoImpresaPreventivo, getRichiestaElencoImpresa } from "services/richiesta_elenco_impresa_esteso";
import ConfirmationPreventivoElenco from "template/ConfirmationPreventivoElenco";
import ConfirmationDifferita from "template/ConfirmationDifferita";
import { getCodici_Ateco } from "services/codici_atecoService";
import { getComuni_Catastali } from "services/comuni_catastaliService";
import { getWs_Provincieinfocamere } from "services/ws_provincieinfocamereService";

import { useLocation } from 'react-router-dom';



type Props = {
    // name: string;
    // path: string;
    numero_imprese: Number
    getData: (page, pageSize, searchKey, datiricerca) => void
    getDataProvincie:(page, pageSize) => void
    getDatiVisura: (cciaa, nrea, getDataBilancio, idprodotto, tipovisura) => void
    getDataComuni:(page, pageSize) => void
    //getDataCodiceAteco: (page, pageSize) => void

};
export const RicercaImpresa: React.FC<Props> = ({getData, numero_imprese, getDataProvincie, getDatiVisura,getDataComuni}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [showDetail, setShowDetail] = useState(false);
    const [showNoAcquisto, setNoAcquisto] = useState(false);
    const [showErrorGenerico, setErroreGenerico] = useState(false);
    const [search, setSearch] = useState('');
    const [rowData, setRowData] = useState(undefined);
    const [rowDataVisura, setRowDataVisura] = useState(undefined);
    
    const rDataProvincia = useSelector((state: RootState) => state.ws_provincieinfocamere);
    const rDataComune = useSelector((state: RootState) => state.comuni_catastali);
    //const rDataAteco = useSelector((state: RootState) => state.codici_ateco);

    const [rDataAteco, setrDataAteco] = useState(undefined);

    //let responseData = new Array();
    const rData = useSelector((state: RootState) => state.trova_impresa);
    const [isLoading, setIsLoading] = useState(false);
    const [provincia, setProvincia] = useState("");

    const [disableRadioDen, setdisableRadioDen] = useState(false);
    const [disableRadioPiva, setdisableRadioPiva] = useState(true);
    const [disableRadioCf, setdisableRadioCf] = useState(true);
    const [checkRadioDen, setcheckRadioDen] = useState(true);
    const [checkRadioPiva, setcheckRadioPiva] = useState(false);
    const [checkRadioCf, setcheckRadioCf] = useState(false);
    const [showRichiestaVisura, setRichiestaVisura] = useState(false);

    const [showTipoRicerca, setTipoRicerca] = useState("");
    const [showDatiRicerca, setDatiRicerca] = useState(undefined);

    const { state } = useLocation();
    const { row } = state || {};
    const { tipo } = state || {};

    type OptionTypeDropDown = {
        value: string;
        label: string;
    };

    type OptionTypeDatiRicerca = {
        statoImpresa: any;
        addetti: any;
        capitaleSoc: any;
        classeForma: any;
        provincia: any;
        comune: any;
        dtIscrizioneMin: any;
        dtIscrizioneMax: any;
        dtCancellazioneMin: any;
        dtCancellazioneMax: any;
        codiceAttivita: any;
    };


    type OptionTypeRowDataPreventivo = {
        esito: string;
        numero_posizioni_preventivo: string;
        numero_posizioni_disponibili: string;
        numero_posizioni_residue: string;
        messaggio: string;


    };
    
    const provinciaDefault: OptionTypeDropDown = {
        value: "",
        label: ""
    }
    const multiValueProvincie =  null
    const provicieLista: OptionTypeDropDown [] = []
    const [selectedProvincia, setSelectedProvincia] = useState(multiValueProvincie);
    const [selectedProvincie, setProvincie] = useState(provicieLista);
    const [showDetailPuntuale, setShowDetailPuntuale] = useState(false);

    const selectProvincia = () => {
        getWs_Provincieinfocamere(1, 1000, '').then((response)=> {
            if (response && response.records) {
                const provincie : OptionTypeDropDown[] = []
                response.records?.map((item, i) => {
                    const provincia: OptionTypeDropDown = {
                        value: "",
                        label: ""
                    }
                    provincia.label = item.DESCRIZIONE
                    provincia.value = item.SIGLAPROVINCIA
                    provincie.push(provincia)
                })
        
                setProvincie(provincie)
            }
        })
    }

    const rowDataPreventivoInfo: OptionTypeRowDataPreventivo = {
        esito: "",
        numero_posizioni_preventivo: "",
        numero_posizioni_disponibili: "",
        numero_posizioni_residue: "",
        messaggio: ""
    }
    const [showDatiRichiestaPreventivo, setshowDatiRichiestaPreventivo] = useState(false);
    const [rowDataPreventivo, setRowDataPreventivo] = useState(undefined);
    const [rowDataResponsePreventivo, setrowDataResponsePreventivo] = useState(undefined)
    const getDatiRicercaImpreseEsteso = (datiricerca) => {
        const rowDataPreventivo: OptionTypeRowDataPreventivo = {
            esito: "",
            numero_posizioni_preventivo: "",
            numero_posizioni_disponibili: "",
            numero_posizioni_residue: "",
            messaggio: ""
        }
        setIsLoading(true);
        getRicercaElencoImpresaPreventivo(datiricerca).then((response) => {
          if (response != null && response.data.message === "OK") {
            //dispatch(setElenco_Imprese_Esteso_EstrazioneList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
            //dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Visura Camerale Richiesta"));
           

            if(response.data.status === 300){
                rowDataPreventivo.esito = "300";
                rowDataPreventivo.messaggio = response.data.response;
            }else if(response.data.status === 200){

                const posizioni_disponibili = response.data.anagrafiche;//da prendere da abbonamento
                const posizioni_preventivo = response.data.response.numeroposizioni[0];
                const posizioni_preventivo_2 = response.data.response.posizionepreventivo;
                const posizioni_residue = Number(posizioni_disponibili) - Number(posizioni_preventivo);
                let nessuna_posizione = false;
                if(Number(posizioni_preventivo) === 0) nessuna_posizione = true;

                if(Number(posizioni_preventivo) === 0 && nessuna_posizione){
                    rowDataPreventivo.esito = "201";
                    rowDataPreventivo.messaggio = "Non hai sufficienti crediti per poter visualizzare questo elenco";
                    rowDataPreventivo.numero_posizioni_preventivo = '0';
                    rowDataPreventivo.numero_posizioni_disponibili = '0';

                }else if(posizioni_residue < 0 && !nessuna_posizione){
                    rowDataPreventivo.esito = "301";
                    rowDataPreventivo.messaggio = "Non hai sufficienti crediti per poter visualizzare questo elenco";
                    rowDataPreventivo.numero_posizioni_preventivo = '0';
                    rowDataPreventivo.numero_posizioni_disponibili = '0';

                }else{
                    rowDataPreventivo.esito = "200";
                    rowDataPreventivo.messaggio = "La ricerca ha prodotto n: "+ posizioni_preventivo.toString();
                    rowDataPreventivo.numero_posizioni_preventivo = posizioni_preventivo.toString();
                    rowDataPreventivo.numero_posizioni_disponibili = posizioni_disponibili.toString();
                    rowDataPreventivo.numero_posizioni_residue = posizioni_residue.toString();
                    setrowDataResponsePreventivo(response.data.response)

                }
            }

            setIsLoading(false);
            setRowDataPreventivo(rowDataPreventivo);
            setshowDatiRichiestaPreventivo(true)
            
          } else {
            //dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Visura non richiedibile"));
            if(response.data.status === 500){
                rowDataPreventivo.esito = "500";
                rowDataPreventivo.messaggio = "Abbiamo rilevato un errore generico! Riprovare!";
            }
            setIsLoading(false);
            setRowDataPreventivo(rowDataPreventivo);
            setshowDatiRichiestaPreventivo(true)
          }
        })
    }


    const [showConfermaRichiestaElenco, setshowConfermaRichiestaElenco] = useState(false);
    const [rowDataRispostaElenco, setrowDataRispostaElenco] = useState(undefined);
    const [rowResponseDataRispostaElenco, setrowResponseDataRispostaElenco] = useState(undefined)

    const handleServerRichestaEleneco = () => {
        if(rowDataResponsePreventivo != null){
            getRichiestaElencoImpresa(rowDataResponsePreventivo).then((response) => {
                if (response != null && response.data.message === "OK") {
                    setshowDatiRichiestaPreventivo(false)
                    setrowResponseDataRispostaElenco(response.data)
                    setshowConfermaRichiestaElenco(true)
                    
                    
                }else{
                    setshowDatiRichiestaPreventivo(false)
                    setrowResponseDataRispostaElenco(response.data)
                    setshowConfermaRichiestaElenco(true)
                    
                }
            });
        }
    }

    const handleServerRedirectDifferita = () => {

        navigate('/ws_ticket', { replace: true });
    }

      

    const multiValueStatoAttivita =  null
    const statoAttivitaLista: OptionTypeDropDown [] = []
    const [selectedStatoAttivita, setStatoAttivita] = useState(multiValueStatoAttivita);
    const [selectedStatiAttivita, setStatiAttivita] = useState(statoAttivitaLista);

    const selectStatoAttiviaImpresa = () =>{
        let stati : OptionTypeDropDown[] = []

        const statiDisponibili = [
            {
                value: "T",
                label: "Tutte le Tipologie"
            },
            {
                value: "A",
                label: "Imprese Attive"

            },
            {
                value: "I",
                label: "Imprese Inattive"

            },
            {
                value: "C",
                label: "Imprese Cessate"

            },
            {
                value: "S",
                label: "Imprese Sospese"

            },
            {
                value: "R",
                label: "Imprese Registrate (Attive, Inattive, Sospese)"

            }
        ]

        stati = statiDisponibili;

        setStatiAttivita(stati);

    }

    const multiValueFormaGiuridica =  null
    const formaGiuridicaLista: OptionTypeDropDown [] = []
    const [selectedFormaGiuridica, setFormaGiuridica] = useState(multiValueFormaGiuridica);
    const [selectedFormeGiuridiche, setFormeGiuridiche] = useState(formaGiuridicaLista);

    const selectFormaGiuridicaImpresa = () =>{
        let formaGiuridica : OptionTypeDropDown[] = []

        const formeGiuridiche = [
            {
                value: "",
                label: "Tutte le Forme Giuridiche"
            },
            {
                value: "CAP",
                label: "Società Di Capitale"

            },
            {
                value: "PER",
                label: "Società di Persone"

            },
            {
                value: "ALT",
                label: "Altre Forme"

            }
        ]

        formaGiuridica = formeGiuridiche;

        setFormeGiuridiche(formaGiuridica);

    }

    const multiValueComuni =  null
    const comuniLista: OptionTypeDropDown [] = []
    const [selectedComune, setSelectedComune] = useState(multiValueComuni);
    const [selectedComuni, setSelectedComuni] = useState(comuniLista);

    const selectComuni = () => {
        getComuni_Catastali(1, 9000, '').then((response)=> {
            if (response && response.records) {
                const comuni : OptionTypeDropDown[] = []
                response.records?.map((item, i) => {
                    const comune: OptionTypeDropDown = {
                        value: "",
                        label: ""
                    }
                    comune.label = item.nomecomune
                    comune.value = item.codicecomune
                    comuni.push(comune)
                })
        
                setSelectedComuni(comuni)
            }
        })
    }

    const multiValueCodiceAteco =  null
    const codiciAtacoLista: OptionTypeDropDown [] = []
    const [selectedCodiceAteco, setSelectedCodiceAteco] = useState(multiValueCodiceAteco);
    const [selectedCodiciAteco, setSelectedCodiciAteco] = useState(codiciAtacoLista);

    const selectCodiciAteco = () => {
        getCodici_Ateco(1, 1000, '').then((response)=> {
            if (response && response.records) {
              const codiciAteco : OptionTypeDropDown[] = []
              response.records?.map((item, i) => {
                  const ateco: OptionTypeDropDown = {
                      value: "",
                      label: ""
                  }
                  ateco.label = item.descrizione
                  ateco.value = item.codice.toString()
                  codiciAteco.push(ateco)
              })
              setSelectedCodiciAteco(codiciAteco)
            }
        })
    }

    const multiValueCapSociale =  null
    const capitaleSocialeLista: OptionTypeDropDown [] = []
    const [selectedCapitaleSociale, setSelectedCapitaleSociale] = useState(multiValueCapSociale);
    const [selectedCapitaliSociale, setSelectedCapitaliSociale] = useState(capitaleSocialeLista);

    const selectCapitaleSocialeImpresa = () =>{
        let capitaliSociale : OptionTypeDropDown[] = []

        const capitaleSociale = [
            {
                value: "0",
                label: "< 10.000"
            },
            {
                value: "1",
                label: "10.000-14.999,99"

            },
            {
                value: "2",
                label: "15000-24.999,99"

            },
            {
                value: "3",
                label: "25.000-49.999,99"

            },
            {
                value: "4",
                label: "50.000-74.999,99"

            }
            ,
            {
                value: "5",
                label: "75.000-99.999,99"

            }
            ,
            {
                value: "6",
                label: "100.000-149.999,99"

            },
            {
                value: "7",
                label: "150.000-499.999,99"

            },
            {
                value: "8",
                label: "500.000-1.499.999,99"

            },
            {
                value: "9",
                label: "1.500.000-4.999.999,99"

            },
            {
                value: "10",
                label: ">5.000.000"

            }
            
        ]

        capitaliSociale = capitaleSociale;

        setSelectedCapitaliSociale(capitaliSociale);

    }

    const multiValueClasseAddetti =  null
    const classeAddettiLista: OptionTypeDropDown [] = []
    const [selectedClasseAddetti, setSelectedClasseAddetti] = useState(multiValueClasseAddetti);
    const [selectedClassiAddetti, setSelectedClassiAddetti] = useState(classeAddettiLista);

    const selectClassiAddettiImpresa = () =>{
        let classiAddetti : OptionTypeDropDown[] = []

        const classeAddetti = [
            {
                value: "0",
                label: "nessun addetto"
            },
            {
                value: "1",
                label: "un solo addetto"

            },
            {
                value: "2",
                label: "da 2 a 5"

            },
            {
                value: "3",
                label: "da 6 a 10"

            },
            {
                value: "4",
                label: "da 11 a 15"

            }
            ,
            {
                value: "5",
                label: "da 16 a 25"

            }
            ,
            {
                value: "6",
                label: "da 26 a 50"

            },
            {
                value: "7",
                label: "da 51 a 100"

            },
            {
                value: "8",
                label: "da 101 a 200"

            },
            {
                value: "9",
                label: "da 201 a 500"

            },
            {
                value: "10",
                label: "maggiore di 500"

            }
            
        ]

        classiAddetti = classeAddetti;

        setSelectedClassiAddetti(classiAddetti);

    }


    const handleSetRicerca = (tipo) => {
        //console.log("-----------")
    }

    

    useEffect(() => {
        //getDataProvincie(1, 10000);
        //getDataCodiceAteco(1, 10000);
        //getDataComuni(1, 10000);
        selectProvincia();
        selectStatoAttiviaImpresa();
        selectFormaGiuridicaImpresa();
        selectComuni();
        selectCodiciAteco();
        selectCapitaleSocialeImpresa();
        selectClassiAddettiImpresa();

      }, []);

    const handleCardClick = (path) => {
        dispatch(activateMenu(path))
        navigate(path, { replace: true });
    }

    const handleSearch = () => {
        setSearch(search);
        //console.log(search)
        //console.log(checkRadioDen)
        //console.log(checkRadioPiva)
        //console.log(checkRadioCf)

        let tiporicerca = 0;
        if(checkRadioDen) tiporicerca = 1;
        if(checkRadioCf) tiporicerca = 2;
        if(checkRadioPiva) tiporicerca = 3;
        // //if (search.length > 0) {
        //     getTrovaImpresa(datiricerca, 0, 0).then((response)=> {
        //         //console.log(response.data)
        //         responseData = response.data.response.impresa
        //         setResponseData(responseData)
        //     })
        // //}
        var datiricerca = {
            "denominazione": encodeURIComponent(search).replace("%20","+"),
            "provincia": provincia,
            "partitaiva": search,
            "codicefiscale": search,
            "tiporicerca": tiporicerca
        }

        getData(1, 20, search, datiricerca)


    }

    const [ricercaImpresaEstesa, setRicercaImpresaEstesa] = useState(false);

    const handleSearchEstesa = () => {
        ////console.log(selectedProvincie);
        ////console.log("-------")
        ////console.log(selectedProvincia);

        //console.log(dataIscrizioneDa);


        if(selectedProvincia==null || selectedProvincia.length === 0){
            //console.log("provincie non settate");
            setRicercaImpresaEstesa(true);
            
        }else{
            var datiricerca = {
                "provincie": selectedProvincia,
                "comuni": selectedComune,
                "formagiuridica": selectedFormaGiuridica,
                "statoattivita": selectedStatoAttivita,
                "ateco": selectedCodiceAteco,
                "capitalesociale": selectedCapitaleSociale,
                "addetti": selectedClasseAddetti,
                "dataiscizioneda": dataIscrizioneDa,
                "dataiscizionea": dataIscrizioneA,
                "datacancellazioneda": dataCancellazioneDa,
                "datacancellazionea": dataCancellazioneA,
              }
            getDatiRicercaImpreseEsteso(datiricerca);
            
        }


    }

    const handleDenominazioneValue = async (search) => {
        setSearch(search);
        ////console.log(search)
    }

    const handlePartitaIvaValue = async (partitaiva) =>{
        setSearch(partitaiva);

    }
    const handleCodiceFiscaleValue = async (codicefiscale) =>{
        setSearch(codicefiscale);

    }


    const handleRowDetailClick = (row) => {
        setIsLoading(true);
        var datiricerca = {
            "chiveimpresa": row["chiave-impresa"],
            "idprodotto": 2
        }
        getTrovaImpresaPec(datiricerca).then((response)=> {
            //setRowData(row);
            
            if (response && response.data.response != null) {

              if(response.data.status === 203){
                setNoAcquisto(true);
                setIsLoading(false);
              }else if(response.data.status > 250){
                setErroreGenerico(true);
                setIsLoading(false);
              }else if(response.data.response.imprese !=null){
                numero_imprese = response.data.NumeroPosizioniTotali
                setRowData(response.data.response.impresa[0]);
                setShowDetail(true);
                setIsLoading(false);
              }else{
                setIsLoading(false);
              } 
              
            } else {
            //   dispatch(setTrovaImpresaMessage("Nessun dato presente in questa sezione"));
            setIsLoading(false);
            }
          })
        
    }

    

    const handleServerDettaglioPuntualeImpresa = async (row) => {
        setRowData(row);
        setShowDetailPuntuale(true)

    }

    const handleServerDelete = async () => {}

    const handleRadioRicercaDenominazione = async () => {

        setdisableRadioDen(false)
        setdisableRadioPiva(true)
        setdisableRadioCf(true)

        setcheckRadioDen(true)
        setcheckRadioCf(false)
        setcheckRadioPiva(false)


    }
    const handleRadioRicercaPiva = async () => {

        setdisableRadioDen(true)
        setdisableRadioPiva(false)
        setdisableRadioCf(true)

        setcheckRadioDen(false)
        setcheckRadioCf(false)
        setcheckRadioPiva(true)


    }

    const handleRadioRicercaCf = async () => {

        setdisableRadioDen(true)
        setdisableRadioPiva(true)
        setdisableRadioCf(false)
        
        setcheckRadioDen(false)
        setcheckRadioCf(true)
        setcheckRadioPiva(false)

    }

    const visualizzaUnitaLocali = async (unitaLocali) =>{
        unitaLocali.forEach((el) =>{
            return  <div className="col-sm-12">Denominazione: {el.StatoAttivitaR }</div>
        })
    }
    const handleRowConfermaRichiestaVisuraClick = (row) => {
        //setRowData(row);
        setRowDataVisura(row)
        setRichiestaVisura(true);
    }

    const handleServerVisura = async () => {
        if (rowDataVisura) {
            setRichiestaVisura(false);
            handleRowVisura(rowDataVisura.cciaa[0],rowDataVisura.nrea[0], rowDataVisura.denominazione[0], rowDataVisura.StatoAttivita[0]);      
        } else {
            //dispatch(setElenco_Imprese_EstesoMessage("Some error occured!"));
        }
    }

    const handleRowVisura = (cciaa, nrea, nomeanagrafica, stato_attivita) => {
        const idprodotto = 7;
        if(stato_attivita.toUpperCase() !== 'A'.toUpperCase()){ 
            getDatiVisura(cciaa, nrea, nomeanagrafica, idprodotto, "VISSTO");
        }else{
            getDatiVisura(cciaa, nrea, nomeanagrafica, idprodotto, "VISORDIN");
        }
        
    }

    const MultiSelect = ({valueList, onChange, options, maxLimit = 5}) => {
        return (
          <Select
            value={valueList}
            isMulti
            onChange={onChange}
            options={options}
            isOptionDisabled={(option) => valueList.length >= maxLimit}
          />
        );
      };

    const [dataIscrizioneDa, setDataIscrizioneDa] = useState('');
    const [dataIscrizioneA, setDataIscrizioneA] = useState('');

    const [dataCancellazioneDa, setDataCancellazioneDa] = useState('');
    const [dataCancellazioneA, setDataCancellazioneA] = useState('');
    

    const borderColorInfo = (row) => {
        if(row.stato[0] !== 'Attiva'){
            return "col-sm-12 riga-trova-impresa border-left-8-red background-row-white shadow";
        }else{
            return "col-sm-12 riga-trova-impresa border-left-8-green background-row-white shadow";
        }
    }
    

    return (
        <Col md={12} xl={12} xs={12} className="cursor-pointer mb-4 shadow">
        {isLoading ? <LoadingSpinner /> : isLoading}
            <Row>
                <Card.Body>
                    <Tabs
                        defaultActiveKey="estesa"
                        //activeKey={showTipoRicerca}
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        fill
                    >
                    <Tab eventKey="puntuale" title="Ricerca Puntuale">
                    <Row>
                        <Col md={9} xl={9} xs={12} className="padding-bottom20">
                        <div className="ampiezzamax">
                            <label className="containerradio ampiezzamax">
                            <input type="radio" name="gender" value="other" checked={checkRadioDen}
                                onClick={(e) => handleRadioRicercaDenominazione()}
                            />
                            
                            <InputGroup>
                                <Form.Control
                                    placeholder="Cerca per Denominazione"
                                    aria-label="Search"
                                    aria-describedby="basic-search"
                                    onChange={(e) => handleDenominazioneValue(e.target.value)}
                                    className="heigth-4"
                                    disabled={disableRadioDen}
                                />
                            </InputGroup>
                            <span className="checkmark"></span>
                            </label>
                        </div>
                                    
                        </Col>
                        <Col md={3} xl={3} xs={12} className="ddl-prov padding-bottom20">
                            <InputGroup>
                                <Form.Control
                                    as="select"
                                    value={provincia}
                                    onChange={e => {
                                        //console.log("e.target.value", e.target.value);
                                        setProvincia(e.target.value);
                                    }}
                                    className=" heigth-4"
                                    disabled={disableRadioDen}
                                >
                                    <option value="">Seleziona Provincia</option>
                                    {rDataProvincia.list?.map((item, i) => {

                                        return  <option value={item.SIGLAPROVINCIA}>{item.DESCRIZIONE}</option>

                                    })}
                                </Form.Control>
                            </InputGroup>
                        </Col>
                        <Col md={12} xl={12} xs={12} className="padding-bottom20">
                            <div className="ampiezzamax">
                                <label className="containerradio ampiezzamax">
                                <input type="radio" name="gender" value="other" checked={checkRadioPiva}
                                    onClick={(e) => handleRadioRicercaPiva()}
                                />
                                <InputGroup>
                                    <Form.Control
                                        placeholder="Cerca per Partita Iva"
                                        aria-label="Search"
                                        aria-describedby="basic-search"
                                        onChange={(e) => handlePartitaIvaValue(e.target.value)}
                                        className="heigth-4"
                                        disabled={disableRadioPiva}
                                    />
                                </InputGroup>
                                <span className="checkmark"></span>
                                </label>
                            </div>
                        </Col>
                        <Col md={12} xl={12} xs={12} className="padding-bottom20">
                            <div className="ampiezzamax">
                                <label className="containerradio ampiezzamax">
                                <input type="radio" name="gender" value="other"  checked={checkRadioCf}
                                    onClick={(e) => handleRadioRicercaCf()}
                                />
                                <InputGroup>
                                    <Form.Control
                                        placeholder="Cerca per Codice Fiscale"
                                        aria-label="Search"
                                        aria-describedby="basic-search"
                                        onChange={(e) => handleCodiceFiscaleValue(e.target.value)}
                                        className="heigth-4"
                                        disabled={disableRadioCf}
                                    />
                                </InputGroup>
                                <span className="checkmark"></span>
                                </label>
                            </div>
                            
                        </Col>
                        <Col md={12} xl={12} xs={12}>
                            <InputGroup>
                                <Button disabled={search.length <= 3} variant="dark" className="component-maxwidth heigth-4" id="button-search" onClick={handleSearch}>
                                    Cerca
                                </Button>
                            </InputGroup>
                        </Col>
                    </Row>
                <Row className=" ">
                <Card.Body>
                    {/* <div>Risultati Trovati:{rData.list.length}</div> */}
                    <Row>
                        {rData.list.map((item, i) => {
                        return (<div className={borderColorInfo(item)}>
                            <div className="row">
                                <div className="col-sm-1">
                                    <i className="fa-solid fa-building icon-azienda-trova-impresa"></i>
                                </div>
                                <div className="col-sm-9">
                                    <div className="row">
                                        <div className="col-sm-12">Denominazione: <strong>{item.denominazione[0]}</strong></div>
                                        <div className="col-sm-6">Codice Fiscale: <strong>{item.codicefiscale[0]}</strong></div>
                                        <div className="col-sm-6">Partita Iva: <strong>{item.partitaiva[0]}</strong></div>
                                        <div className="col-sm-6">Cciaa-NRea: <strong>{item.cciaa[0]} - {item.nrea[0]}</strong></div>
                                        {/* <div className="col-sm-6">Provincia Sede: <strong>{item.descrprovincia}</strong></div> */}
                                        <div className="col-sm-6">Indirizzo: <strong>{item.indirizzo}</strong></div>
                                        <div className="col-sm-6">Forma Giuridica: <strong>{item.descrnaturagiuridica[0]}</strong></div>
                                        <div className="col-sm-6">Stato: <strong>{item.stato[0]}</strong></div>
                                        <div className="col-sm-6">Ateco Sede: <strong>{item.codiceattivita[0]}</strong></div>
                                        <div className="col-sm-6">Classificazione: <strong>{item.descrizionecodifica[0]}</strong></div>
                                        <div className="col-sm-6">Pec: <strong>{item.pec[0]}</strong></div>
                                        <div className="col-sm-12">Descrizione Attività: <strong>{item.descrizioneattivita[0]}</strong></div>




                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Button variant="link" className="btn-sm" onClick={() => handleRowConfermaRichiestaVisuraClick(item)}>
                                                <i className="fa fa-file-text icon-size-small decoration-none"></i> <span className="font-size-1"><strong>Visura Camerale</strong></span></Button>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                        </div>)
                    })}
                    </Row>
                    {/* <ConfirmationModal 
                                buttonNegative="Cancel" 
                                buttonPositive="Delete" 
                                title="Delete Confirmation" 
                                show={showDelete} 
                                body={rowData} 
                                onNegative={() => setShowDelete(false)} 
                                onPositive={handleServerDelete} /> */}
                    <DetailImpresaPuntualeModal buttonNegative="Chiudi" buttonPositive="Chiudi" title="Dettaglio Impresa" show={showDetailPuntuale} body={rowData} onNegative={() => setShowDetailPuntuale(false)} />
                    {/* <DetailModal buttonNegative="Chiudi" buttonPositive="Chiudi" title="Dettaglio Impresa" show={showDetail} body={rowData} onNegative={() => setShowDetail(false)} /> */}
                    {<ConfirmationModalNoAcquisto
                                buttonNegative="Chiudi" 
                                buttonPositive="Chiudi" 
                                title="Residuo Dettaglio Impresa & PEC Insufficiente" 
                                show={showNoAcquisto} 
                                body="Il numero di imprese su cui è possibile richiedere il dettaglio e la pec previsto dal tuo abbonamento è terminato. Per proseguire è necessario ricaricare il tuo abbonamento. Grazie"
                                onNegative={() => setNoAcquisto(false)} 
                                onPositive={handleServerDelete} />}
                    <ModalErrorGenerico buttonNegative="Chiudi" 
                                buttonPositive="Chiudi" 
                                title="Errore Generico Nella Richiesta" 
                                show={showErrorGenerico} 
                                body="Abbiamo rilevato un errore generico nella richiesta. Riprovare, se il problema persiste contattare il servio vliente. Grazie"
                                onNegative={() => setErroreGenerico(false)} 
                                onPositive={handleServerDelete} />
                    <ConfirmationVisura buttonNegative="Annulla" buttonPositive="Continua" title="Richiesta documento" show={showRichiestaVisura} body={"Sei sicuro di voler richiedere la visura di questa società?"} onNegative={() => setRichiestaVisura(false)} onPositive={handleServerVisura} />
                    </Card.Body>
                    </Row>
                    </Tab>
                    <Tab eventKey="estesa" title="Ricerca Estesa">
                        <Row className="shadow box-ricerca-elenco">
                            <Col md={12} xl={12} xs={12} className="padding-bottom20">
                                <p className="title-style-elenco">Ricerca per Criterio Territoriale</p>
                            </Col>
                            <Col md={6} xl={6} xs={12} className="ddl-prov padding-bottom20">
                                <InputGroup>
                                    <label className="title-campo-style-elenco">Provincia Sede (Inserire massimo 3 Provincie) *</label>
                                    <Select
                                        //defaultValue={selectedProvincia}
                                        value={selectedProvincia}
                                        onChange={(e) => setSelectedProvincia(e)}
                                        isMulti
                                        name="value"
                                        options={selectedProvincie}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Seleziona Provincia"
                                        isOptionDisabled={() => selectedProvincia != null && selectedProvincia?.length >= 5}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={6} xl={6} xs={12} className="padding-bottom20">
                                <InputGroup>
                                    <label className="title-campo-style-elenco">Comune Sede (Inserire massimo 3 Provincie)</label>
                                    <Select
                                        //defaultValue={selectedProvincia}
                                        value={selectedComune}
                                        onChange={(e) => setSelectedComune(e)}
                                        isMulti
                                        name="value"
                                        options={selectedComuni}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Seleziona Comune"
                                        isOptionDisabled={() => selectedComune != null && selectedComune?.length >= 5}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="shadow box-ricerca-elenco">
                            <Col md={12} xl={12} xs={12} className="padding-bottom20">
                                <p className="title-style-elenco">Ricerca per Forma Giuridica e Stato Attività</p>
                            </Col>
                            <Col md={6} xl={6} xs={12} className="padding-bottom20">
                                <InputGroup>
                                    <label className="title-campo-style-elenco">Forma Giuridica</label>
                                    <Select
                                        //defaultValue={selectedProvincia}
                                        value={selectedFormaGiuridica}
                                        onChange={(e) => setFormaGiuridica(e)}
                                        name="value"
                                        options={selectedFormeGiuridiche}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Seleziona Forma Giuridica"
                                        //isOptionDisabled={() => selectedStatoAttivita != null && sele?.length >= 3}
                                    />
                                </InputGroup>    
                            </Col>
                            <Col md={6} xl={6} xs={12} className="padding-bottom20">
                                <InputGroup>
                                    <label className="title-campo-style-elenco">Stato Attività</label>
                                    <Select
                                        //defaultValue={selectedProvincia}
                                        value={selectedStatoAttivita}
                                        onChange={(e) => setStatoAttivita(e)}
                                        name="value"
                                        options={selectedStatiAttivita}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Seleziona Stato Attività"
                                        //isOptionDisabled={() => selectedStatoAttivita != null && sele?.length >= 3}
                                    />
                                </InputGroup>    
                            </Col>
                        </Row>
                        <Row className="shadow box-ricerca-elenco">
                            <Col md={12} xl={12} xs={12} className="padding-bottom20">
                                    <p className="title-style-elenco">Ricerca per Settore Capitale e Addetti</p>
                            </Col>
                            <Col md={6} xl={6} xs={12} className="ddl-prov padding-bottom20">
                                <InputGroup>
                                    <label className="title-campo-style-elenco">Settore Aziendale (Inserire massimo 3 valori)</label>
                                    <Select
                                        //defaultValue={selectedProvincia}
                                        value={selectedCodiceAteco}
                                        onChange={(e) => setSelectedCodiceAteco(e)}
                                        isMulti
                                        name="value"
                                        options={selectedCodiciAteco}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Seleziona Codice Ateco"
                                        isOptionDisabled={() => selectedCodiceAteco != null && selectedCodiceAteco?.length >= 3}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={6} xl={6} xs={12} className="ddl-prov padding-bottom20">
                                <InputGroup>
                                    <label className="title-campo-style-elenco">Valore Capitale Sociale (Inserire massimo 3 valori)</label>
                                    <Select
                                        //defaultValue={selectedProvincia}
                                        value={selectedCapitaleSociale}
                                        onChange={(e) => setSelectedCapitaleSociale(e)}
                                        isMulti
                                        name="value"
                                        options={selectedCapitaliSociale}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Seleziona Capitale Sociale"
                                        isOptionDisabled={() => selectedCapitaleSociale != null && selectedCapitaleSociale?.length >= 3}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={6} xl={6} xs={12} className="ddl-prov padding-bottom20">
                                <InputGroup>
                                    <label className="title-campo-style-elenco">Numero Addetti (Inserire massimo 3 valori)</label>
                                    <Select
                                        //defaultValue={selectedProvincia}
                                        value={selectedClasseAddetti}
                                        onChange={(e) => setSelectedClasseAddetti(e)}
                                        isMulti
                                        name="value"
                                        options={selectedClassiAddetti}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Seleziona Numero Addetti"
                                        isOptionDisabled={() => selectedClasseAddetti != null && selectedClasseAddetti?.length >= 3}
                                    />
                                </InputGroup>
                            </Col>
                            {/* <Col md={6} xl={6} xs={12} className="ddl-prov padding-bottom20">
                                <InputGroup>
                                    <Select
                                        //defaultValue={selectedProvincia}
                                        value={selectedClasseAddetti}
                                        onChange={(e) => setSelectedClasseAddetti(e)}
                                        isMulti
                                        name="value"
                                        options={selectedClassiAddetti}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Seleziona Numero Addetti"
                                        isOptionDisabled={() => selectedClasseAddetti != null && selectedClasseAddetti?.length >= 3}
                                    />
                                </InputGroup>
                            </Col> */}
                        </Row>
                        <Row className="shadow box-ricerca-elenco">
                            <Col md={12} xl={12} xs={12} className="padding-bottom20">
                                    <p className="title-style-elenco">Ricerca per Data Iscrizione e Cancellazione</p>
                            </Col>
                            <Col md={6} xl={6} xs={12} className="ddl-prov padding-bottom20">
                                <label className="title-campo-style-elenco">Data Iscrizione Dal</label>
                                <InputGroup>
                                    <Form.Control
                                        type="date"
                                        name="dataiscrizioneda"
                                        placeholder="Due date"
                                        value={dataIscrizioneDa}
                                        onChange={(e) => setDataIscrizioneDa(e.target.value)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={6} xl={6} xs={12} className="ddl-prov padding-bottom20">
                                <label className="title-campo-style-elenco">Data Iscrizione Al</label>
                                <InputGroup>
                                    <Form.Control
                                        type="date"
                                        name="dataiscrizionea"
                                        placeholder="Due date"
                                        value={dataIscrizioneA}
                                        onChange={(e) => setDataIscrizioneA(e.target.value)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={6} xl={6} xs={12} className="ddl-prov padding-bottom20">
                                <label className="title-campo-style-elenco">Data Cancellazione Dal</label>
                                <InputGroup>
                                    <Form.Control
                                        type="date"
                                        name="datacancellazioneda"
                                        placeholder="Due date"
                                        value={dataCancellazioneDa}
                                        onChange={(e) => setDataCancellazioneDa(e.target.value)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={6} xl={6} xs={12} className="ddl-prov padding-bottom20">
                                <label className="title-campo-style-elenco">Data Cancellazione Al</label>
                                <InputGroup>
                                    <Form.Control
                                        type="date"
                                        name="datacancellazionea"
                                        placeholder="Due date"
                                        value={dataCancellazioneA}
                                        onChange={(e) => setDataCancellazioneA(e.target.value)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={12} xl={12} xs={12}>
                                <InputGroup>
                                    <Button  variant="dark" className="component-maxwidth heigth-4" id="button-search" onClick={handleSearchEstesa}>
                                        Cerca
                                    </Button>
                                </InputGroup>
                            </Col>

                        </Row>
                        <ConfirmationDatiObbligatoriElenco 
                            buttonNegative="Continua" 
                            buttonPositive="Continua" 
                            title="Ricerca Imprese Estesa" 
                            show={ricercaImpresaEstesa} 
                            body={"Selezionare almeno una Provincia!"} 
                            onNegative={() => setRicercaImpresaEstesa(false)} 
                            />
                        <ConfirmationPreventivoElenco
                            buttonNegative="Annulla" 
                            buttonPositive="Richiedi" 
                            title="Ricerca Imprese Estesa" 
                            show={showDatiRichiestaPreventivo} 
                            body={rowDataPreventivo} 
                            onNegative={() => setshowDatiRichiestaPreventivo(false)}
                            onPositive={handleServerRichestaEleneco}  
                        
                        />
                        <ConfirmationDifferita
                            buttonNegative="Chiudi" 
                            buttonPositive="Chiudi" 
                            title="Richistea Elenco Imprese" 
                            show={showConfermaRichiestaElenco} 
                            body={rowResponseDataRispostaElenco} 
                            onNegative={() => setshowConfermaRichiestaElenco(false)}
                            onPositive={handleServerRedirectDifferita}  
                        />
                    </Tab>
                    </Tabs>  
                </Card.Body>
            </Row>
        </Col>
        
    );
};

